import { useI18n } from 'hooks/useI18n';
import { useMemo } from 'react';
import { hasCardType } from '../PublicProfileClassic.utils';
import { UseClassicCVEducation } from './ClassicCVEducation.types';
import { getTimeline } from './ClassicCVEducation.utils';

// Hooks.
// -----------------------------------------------------------------------------
export const useClassicCVEducation: UseClassicCVEducation = ({
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------
  const hasData = useMemo(
    () => hasCardType(manfredite, 'education'),
    [manfredite]
  );

  const courses = useMemo(
    () => getTimeline(manfredite, 'COURSE'),
    [manfredite]
  );

  const degrees = useMemo(
    () => getTimeline(manfredite, 'DEGREE'),
    [manfredite]
  );

  const trainings = useMemo(
    () => getTimeline(manfredite, 'SELF_TRAINING'),
    [manfredite]
  );

  // Return.
  // ---------------------------------------------------------------------------
  return {
    courses,
    degrees,
    trainings,
    hasData,
    t
  };
};
