import { StyledFlag } from 'components/shared/BadgeFlag.styled';
import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing
} from '../../../../theme';
import { StyledDesc } from '../ClasicCVCard.styled';

export const StyledCard = styled.div`
  margin-bottom: ${spacing('xl')};
  border-radius: 0 ${radius('lg')} ${radius('lg')} 0;
  border: 1px solid ${color('midnight_200')};

  @media (min-width: ${screen('md')}) {
    display: flex;
  }
`;

export const StyledHighlightImage = styled.div`
  overflow: hidden;
  border-bottom: 1px solid ${color('midnight_200')};
  border-top-right-radius: ${radius('lg')};
  overflow: hidden;

  @media (min-width: ${screen('md')}) {
    border-bottom: 0;
    border-right: 1px solid ${color('midnight_200')};
    border-top-right-radius: 0;
    width: 20rem;
    flex-shrink: 0;
  }

  @media (min-width: ${screen('lg')}) {
    width: 30rem;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: unset !important;
    margin-top: 0;
    object-fit: cover;
    display: block;
  }
`;

export const StyledLogo = styled.div`
  background-color: ${color('midnight_100')};
  padding: 0 ${spacing('lg')} ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    border-top-right-radius: ${radius('lg')};
    padding: 0 ${spacing('md')} ${spacing('lg')} ${spacing('xl')};
  }

  &:last-child {
    border-bottom-right-radius: ${radius('lg')};
  }

  ${StyledFlag} {
    margin-left: calc(-${spacing('lg')} + -${spacing('xs2')});
    transform: translateY(-50%);

    @media (min-width: ${screen('md')}) {
      margin-left: calc(-${spacing('xl')} + -${spacing('xs2')});
      margin-top: -1px;
      margin-bottom: ${spacing('md')};
      transform: unset;
    }
  }
`;

export const StyledInfo = styled.div`
  &:first-child {
    ${StyledLogo} {
      border-top-right-radius: ${radius('lg')};
    }
  }
  @media (min-width: ${screen('md')}) {
    flex-grow: 1;
  }
`;

export const StyledContent = styled.div`
  padding: ${spacing('lg')};
  border-top: 1px solid ${color('midnight_200')};

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('xl')};
    flex-grow: 1;
  }

  ${StyledDesc} {
    padding-top: 0;
  }
`;

export const StyledTitle = styled.h4`
  font-size: ${fontSize('lg')};
  line-height: 1.2;
  font-weight: ${fontWeight('black')};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
    line-height: 1.16;
  }
`;

export const StyledCompany = styled.div`
  margin-top: ${spacing('xs')};
  font-size: ${fontSize('lg')};
  font-weight: ${fontWeight('medium')};
`;

export const StyledExtraContent = styled.div`
  overflow: hidden;
  margin: 0;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;

  &.is-active {
    max-height: var(--content-height);
  }
`;

export const StyledLink = styled.div`
  border-top: 1px solid ${color('midnight_200')};
  margin-top: ${spacing('sm')};
  padding-top: ${spacing('sm')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;
  display: flex;
  gap: ${spacing('xs')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
    line-height: 1.25;
  }

  a {
    font-weight: ${fontWeight('extraBold')};
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
  }
`;
