import styled from 'styled-components';
import { spacing } from 'theme';

export const StyledMarkdown = styled.div`
  ul,
  ol {
    padding-left: ${spacing('xl')} !important;

    li {
      margin-bottom: ${spacing('xs')};
      margin-top: 0 !important;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: numeric;
    }
  }
`;
