import { Button } from '@asgard/ui-library';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { useClassicCVLinkedInButton } from './ClassicCVLinkedInButton.hooks';
import { ClassicCVLinkedInButtonRoot } from './ClassicCVLinkedInButton.styled';
import { ClassicCVLinkedInButtonProps } from './ClassicCVLinkedInButton.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVLinkedInButton: React.FC<
  ClassicCVLinkedInButtonProps
> = ({ hasLinkedIn, onResetLinkedIn }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { onClick, t } = useClassicCVLinkedInButton({ onResetLinkedIn });

  // Guard Condition.
  // ---------------------------------------------------------------------------
  if (!hasLinkedIn) {
    return null;
  }

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <ClassicCVLinkedInButtonRoot>
      <Button
        label={t('linkedInButton')}
        startIcon={faCheckCircle}
        onClick={onClick}
      />
    </ClassicCVLinkedInButtonRoot>
  );
};
