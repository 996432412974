import { HasProfileData, LinkedInImport } from './PublicProfileClassic.types';

// Constants.
// -----------------------------------------------------------------------------
export const QS_AVATAR = 'profilePhoto';
export const QS_INTRODUCTION = 'aboutMe';
export const QS_JOBS = 'jobPositions';
export const QS_LANGUAGES = 'languages';
export const QS_NAME = 'name';
export const QS_POSITION = 'headline';
export const QS_SKILLS = 'technologies';
export const QS_EDUCATIONS = 'educations';
export const QS_WHERE_DO_YOU_LIVE = 'location';

export const DEFAULT_LINKEDIN_IMPORT: LinkedInImport = {
  avatar: false,
  introduction: false,
  jobs: false,
  languages: false,
  name: false,
  position: false,
  technologies: false,
  educations: false,
  whereDoYouLive: false
};

export const DEFAULT_PROFILE_DATA: HasProfileData = {
  bookmarks: false,
  career: false,
  certifications: false,
  education: false,
  highlights: false,
  projects: false
};
