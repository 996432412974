import styled from 'styled-components';

export const StyledSection = styled.div`
  margin-top: 1.27cm;
  display: flex;
  gap: 8.47mm;

  > div {
    width: 50%;
  }
`;
