import CVFilter from 'components/publicProfile/shared/CVFilter';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { Fragment, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { StyledTitle } from '../ClasicCVCard.styled';
import { StyledSection } from '../PublicProfileClassic.styled';
import ClasicCVHighlightCard from './ClasicCVHighlightCard';
import { StyledCards } from './ClassicCVHighlights.styled';

type ClassicCVHighlightsProps = {
  publicManfredite: UIPublicManfredite;
};

const ClassicCVHighlights: React.FC<ClassicCVHighlightsProps> = ({
  publicManfredite: { timeline }
}) => {
  const highlights = timeline?.filter((e) => e.cardType === 'highlight') || [];
  const shouldShowFilter = highlights.length > 8;
  const filters = [...new Set(highlights.map((highlight) => highlight.type))];

  const { t } = useI18n('publicProfile');
  const [activeFilter, setActiveFilter] = useState('');

  const handleOnFilterChange = (newFilter: string): void => {
    setActiveFilter(newFilter);
  };

  return (
    <StyledSection>
      <StyledTitle>{t('classic.sections.highlights')}</StyledTitle>

      {shouldShowFilter && (
        <CVFilter
          onFilterChange={handleOnFilterChange}
          filters={filters}
          filterTranslationPrefix="timeline.type.highlight"
        />
      )}

      <StyledCards>
        {timeline?.map((item, index) => (
          <Fragment key={index}>
            {item.cardType === 'highlight' &&
              (item.type === activeFilter || activeFilter === '') && (
                <Fade>
                  <ClasicCVHighlightCard profileItem={item} />
                </Fade>
              )}
          </Fragment>
        ))}
      </StyledCards>
    </StyledSection>
  );
};

export default ClassicCVHighlights;
