import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faBadgeCheck,
  faBookSpells,
  faBriefcase,
  faDiploma,
  faFileCertificate,
  faFileCode,
  faFilmAlt,
  faGraduationCap,
  faHeadSideHeadphones,
  faKeynote,
  faMedal,
  faNewspaper,
  faRocket
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BadgeFlag from 'components/shared/BadgeFlag';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { useRef, useState } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear, isSameDateYearAndMonth } from 'utils/date';
import {
  StyledCard,
  StyledCardIcon,
  StyledContent,
  StyledDate,
  StyledDateRange,
  StyledDesc,
  StyledEllapsedTime,
  StyledLink,
  StyledLogo,
  StyledTechs,
  StyledTechsItem,
  StyledTechsList,
  StyledTechsLogo,
  StyledTechsTitle,
  StyledTitle,
  StyledTrigger,
  StyledTriggerIcon
} from './PublicProfileCard.styled';

type ProfileCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
  index: number;
};

const PublicProfileCard = ({ profileItem, index }: ProfileCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();
  const content = useRef<HTMLDivElement>(null);
  const currentHeight = content.current?.scrollHeight;

  const [setActive, setActiveState] = useState('');

  const text = getItemTypeText(profileItem);

  function toggleContent(): void {
    setActiveState(setActive === '' ? 'is-active' : '');
  }

  const contentHeight = {
    '--content-height': `${currentHeight}px`
  } as React.CSSProperties;

  const typeIcons: Record<string, IconProp> = {
    career: faBriefcase,
    OPEN_SOURCE: faFileCode,
    SIDE_PROJECT: faBookSpells,
    POST: faNewspaper,
    TALK: faKeynote,
    LAUNCH: faRocket,
    MILESTONE: faBadgeCheck,
    VIDEO: faFilmAlt,
    DEGREE: faGraduationCap,
    COURSE: faDiploma,
    SELF_TRAINING: faHeadSideHeadphones,
    certification: faFileCertificate,
    AWARD: faMedal
  };

  const typeColors: Record<string, string> = {
    OPEN_SOURCE: 'set2_04',
    SIDE_PROJECT: 'set1_05',
    LAUNCH: 'dark2_03',
    MILESTONE: 'set1_02',
    TALK: 'set1_05',
    VIDEO: 'set1_04',
    POST: 'set1_03',
    career: 'set1_09',
    DEGREE: 'set3_06',
    COURSE: 'set3_10',
    SELF_TRAINING: 'set3_05',
    certification: 'set3_01',
    AWARD: 'set1_07'
  };

  function getItemTypeText(item: UIPublicManfrediteTimelineItem): string {
    if (item.cardType === 'career') {
      return t('timeline.type.jobPosition');
    }

    if (item.cardType === 'certification') {
      return t('timeline.type.certification');
    }

    if (item.cardType === 'project') {
      if (!item.type) {
        return '';
      }
      return t(`timeline.type.project.${item.type}`);
    }

    if (item.cardType === 'education') {
      if (!item.type) {
        return '';
      }
      return t(`timeline.type.education.${item.type}`);
    }

    if (item.cardType === 'highlight') {
      return (
        (item.type ? t(`timeline.type.highlight.${item.type}`) : '') +
        (item.category ? `: ${item.category}` : '')
      );
    }
    return '';
  }

  function getItemType(
    item: UIPublicManfrediteTimelineItem
  ): string | undefined {
    if (item.cardType === 'certification' || item.cardType === 'career') {
      return item.cardType;
    } else {
      return item.type;
    }
  }

  const isCertification = getItemType(profileItem) === 'certification';

  const trimmedLink = profileItem.link?.replace(/^https?:\/\//, '');

  return (
    <StyledCard>
      <StyledCardIcon aria-hidden="true">
        <FontAwesomeIcon icon={typeIcons[getItemType(profileItem) || 'POST']} />
      </StyledCardIcon>
      <BadgeFlag
        color={typeColors[getItemType(profileItem) || 'POST']}
        icon={typeIcons[getItemType(profileItem) || 'POST']}
        text={text}
      />

      {profileItem.logoUrl && (
        <StyledLogo src={profileItem.logoUrl} alt={profileItem.logoAlt} />
      )}

      <StyledTitle>{profileItem.company}</StyledTitle>

      {profileItem.link && (
        <StyledLink>
          <a target="_blank" href={profileItem.link} rel="noreferrer">
            {trimmedLink}
          </a>
        </StyledLink>
      )}
      {(profileItem.desc ||
        (profileItem.techs && profileItem.techs.length > 0)) && (
        <StyledTrigger
          type="button"
          id={'button_' + index}
          className={`${setActive}`}
          onClick={toggleContent}
          aria-expanded={setActive === 'is-active'}
          aria-controls={'content_' + index}
        >
          <h4>{profileItem.title}</h4>

          <StyledTriggerIcon>
            <FontAwesomeIcon icon={faAngleDown} />
          </StyledTriggerIcon>
        </StyledTrigger>
      )}

      {!profileItem.desc &&
        profileItem.techs &&
        profileItem.techs.length === 0 && (
          <StyledTrigger as="div">
            <h4>{profileItem.title}</h4>
          </StyledTrigger>
        )}

      {(profileItem.dateInitial || profileItem.dateSingle) && (
        <StyledDate>
          {profileItem.dateInitial &&
            profileItem.dateFinal &&
            !isSameDateYearAndMonth(
              profileItem.dateInitial,
              profileItem.dateFinal
            ) && (
              <>
                <StyledDateRange>
                  <span>
                    {formatDateMonthYear(profileItem.dateInitial, locale)}
                  </span>
                  {profileItem.dateFinal && (
                    <span>
                      {formatDateMonthYear(profileItem.dateFinal, locale)}
                    </span>
                  )}
                </StyledDateRange>
                {
                  <StyledEllapsedTime>
                    (
                    {UIPublicProfileService.formatEllapsedTime(
                      profileItem.dateInitial.toString(),
                      profileItem.dateFinal.toString(),
                      t
                    )}
                    )
                  </StyledEllapsedTime>
                }
              </>
            )}

          {profileItem.dateInitial &&
            profileItem.dateFinal &&
            isSameDateYearAndMonth(
              profileItem.dateInitial,
              profileItem.dateFinal
            ) && (
              <StyledDateRange>
                {formatDateMonthYear(profileItem.dateInitial, locale)}
              </StyledDateRange>
            )}

          {profileItem.dateInitial &&
            !profileItem.dateFinal &&
            !isCertification && (
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                <span>{t('timeline.now')}</span>
              </StyledDateRange>
            )}
          {profileItem.dateInitial &&
            !profileItem.dateFinal &&
            isCertification && (
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                <span>{t('timeline.noExpiryDate')}</span>
              </StyledDateRange>
            )}

          {profileItem.dateSingle && !profileItem.dateInitial && (
            <StyledDateRange>
              {formatDateMonthYear(profileItem.dateSingle, locale)}
            </StyledDateRange>
          )}
        </StyledDate>
      )}
      {(profileItem.desc ||
        (profileItem.techs && profileItem.techs.length > 0)) && (
        <StyledContent
          ref={content}
          id={'content_' + index}
          className={`${setActive}`}
          style={contentHeight}
          role="region"
          aria-labelledby={'button_' + index}
        >
          {profileItem.desc && (
            <StyledDesc>
              <MarkdownComponent content={profileItem.desc} />
            </StyledDesc>
          )}

          {profileItem.link && (
            <StyledLink>
              <a href={profileItem.link}>{trimmedLink}</a>
            </StyledLink>
          )}

          {profileItem.techs && profileItem.techs.length > 0 && (
            <StyledTechs>
              <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
              <StyledTechsList>
                {profileItem.techs?.map((t, i) => (
                  <StyledTechsItem key={i}>
                    <StyledTechsLogo>
                      <img src={t.iconUrl} alt={t.iconAlt} />
                    </StyledTechsLogo>
                    <span>{t.name}</span>
                  </StyledTechsItem>
                ))}
              </StyledTechsList>
            </StyledTechs>
          )}
        </StyledContent>
      )}
    </StyledCard>
  );
};

export default PublicProfileCard;
