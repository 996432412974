import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import {
  StyledShowMore,
  StyledSubSection,
  StyledText,
  StyledTitle
} from './ClassicCVAbout.styled';
import { DEFAULT_FROM_LINKEDIN } from './ClassicCVAboutMarkdownContent.constants';
import { useClassicCVAboutMarkdownContent } from './ClassicCVAboutMarkdownContent.hooks';
import { ClassicCVAboutMarkdownContentProps } from './ClassicCVAboutMarkdownContent.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVAboutMarkdownContent: React.FC<
  ClassicCVAboutMarkdownContentProps
> = ({ fromLinkedIn = DEFAULT_FROM_LINKEDIN, title, content }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { clampableText, displayReadMore, onSetAllText, showAllText, t } =
    useClassicCVAboutMarkdownContent();

  // Guard clauses.
  if (!content || !content.trim()) {
    return null;
  }

  // Functions.
  // ---------------------------------------------------------------------------
  const renderContent = (): JSX.Element => (
    <StyledSubSection>
      <StyledTitle>{title}</StyledTitle>
      <StyledText
        ref={clampableText}
        className={classNames({ 'display-full-text': showAllText })}
      >
        <MarkdownComponent content={content} />
      </StyledText>
      {displayReadMore && (
        <StyledShowMore>
          <button onClick={onSetAllText}>
            <FontAwesomeIcon
              icon={showAllText ? faMinusCircle : faPlusCircle}
            />
            <span>{t(`${showAllText ? 'readLess' : 'readMore'}`)}</span>
          </button>
        </StyledShowMore>
      )}
    </StyledSubSection>
  );

  // Render.
  // ---------------------------------------------------------------------------
  return fromLinkedIn ? (
    <ClassicCVLinkedIn firstOfColumn isSection>
      {renderContent()}
    </ClassicCVLinkedIn>
  ) : (
    renderContent()
  );
};
