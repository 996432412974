import { faBars, faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/shared/Avatar';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import {
  StyledContainer,
  StyledInfo,
  StyledNavBar,
  StyledNavBarInner,
  StyledTabs,
  StyledTrigger
} from './PublicProfileNavBar.styled';

type PublicProfileNavBarProps = {
  publicManfredite: UIPublicManfredite;
  visible: boolean;
  children: React.ReactNode;
  displayMenu: () => void;
  displayTabs: boolean;
  displayTrigger: boolean;
};

const PublicProfileNavBar: React.FC<PublicProfileNavBarProps> = ({
  publicManfredite: { avatarUrl, avatarAlt, firstName, lastName },
  visible,
  children,
  displayMenu,
  displayTabs,
  displayTrigger
}) => {
  const { t } = useI18n('publicProfile');

  return (
    <StyledNavBar visible={visible}>
      <StyledNavBarInner>
        <StyledContainer>
          <StyledInfo>
            {avatarUrl && (
              <Avatar
                avatarImg={avatarUrl}
                avatarAlt={avatarAlt || ''}
                sizes="md"
              />
            )}

            {(firstName || lastName) && (
              <span>
                {firstName} {lastName}
              </span>
            )}

            {!firstName && !lastName && <span>{t('unknownManfredite')}</span>}
          </StyledInfo>
          {displayTrigger && (
            <StyledTrigger onClick={() => displayMenu()}>
              {displayTabs === false && <FontAwesomeIcon icon={faBars} />}
              {displayTabs === true && <FontAwesomeIcon icon={faClose} />}
            </StyledTrigger>
          )}
          <StyledTabs
            role="tablist"
            aria-label={t('tabs.title')}
            className={`${displayTabs && visible ? 'is-visible' : ''}`}
          >
            {children}
          </StyledTabs>
        </StyledContainer>
      </StyledNavBarInner>
    </StyledNavBar>
  );
};

export default PublicProfileNavBar;
