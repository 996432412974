import { Heading2, Heading3 } from 'components/shared/Typography.styled';
import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  shadow,
  spacing
} from 'theme';

export const StyledSection = styled.div`
  margin-top: ${spacing('xl5')};

  &:first-of-type {
    margin-top: ${spacing('xl4')};
  }

  ${Heading2}::before {
    left: 0;
    transform: translateX(0);
  }
`;

export const StyledSubSection = styled.div`
  margin-top: ${spacing('xl4')};

  &:first-of-type {
    margin-top: ${spacing('xl')};
  }

  ${Heading3} {
    margin-bottom: ${spacing('md')};
  }
`;

export const StyledText = styled.div`
  font-size: ${fontSize('md')};
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1em;
  }

  li {
    list-style: disc;
  }
`;

export const StyledSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('md')} ${spacing('xl')};
`;

export const StyledSkill = styled.div`
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  border-radius: ${radius('md')};
  padding: ${spacing('md')};
  display: flex;
  align-items: center;
  gap: ${spacing('md')};
  width: 18.65rem;
  font-weight: ${fontWeight('black')};
  line-height: 1.25;

  img {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const StyledLanguages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('md')};
`;

export const StyledLanguage = styled.div`
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  border-radius: ${radius('md')};
  padding: ${spacing('lg')};
  display: flex;
  align-items: center;
  gap: ${spacing('xl')};
  font-weight: ${fontWeight('black')};
  line-height: 1.25;
`;

export const StyledLanguageName = styled.div`
  font-weight: ${fontWeight('medium')};
  flex: 1 1 auto;
`;

export const StyledTechs = styled.div`
  margin: ${spacing('xl')} auto 0;
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  border-radius: ${radius('md')};
  padding: ${spacing('xl')};

  @media (min-width: ${screen('md')}) {
    min-width: 22rem;
  }
`;

export const StyledTechsTitle = styled.h4`
  margin-top: 0;
  margin-bottom: ${spacing('md')};
  color: ${color('text_subtle')};
  font-size: ${fontSize('xs')};
  line-height: 1;
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  padding-bottom: ${spacing('sm')};
  border-bottom: 1px solid ${color('midnight_300')};
`;

export const StyledTechsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('sm')} ${spacing('xl')};
  margin-bottom: ${spacing('xl3')};

  &:last-of-type {
    margin-bottom: 0;
  }

  li {
    display: flex;
    align-items: center;
    gap: ${spacing('xs')};
    font-weight: ${fontWeight('black')};

    img {
      width: 2.25rem;
      height: 2.25rem;
      filter: grayscale(100%);
    }
  }
`;

export const StyledFacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  max-width: 35rem;
`;

export const StyledFact = styled.div`
  width: 100%;
  background-color: ${color('white')};
  border-radius: ${radius('md')};
  padding: ${spacing('lg')};
`;

export const StyledFactTitle = styled.p`
  margin: 0;
  font-weight: ${fontWeight('black')};
`;

export const StyledFactDesc = styled.div`
  margin-top: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  color: ${color('text_subtle')};

  ul {
    padding-left: 1em;
  }

  li {
    list-style: disc;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;
