import LevelLadder from 'components/shared/levelLadder/LevelLadder';
import { useI18n } from 'hooks/useI18n';
import { isEmpty } from 'lodash';
import { LANGUAGES_LEVELS } from 'model';
import React from 'react';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import { StyledSubSection, StyledTitle } from './ClassicCVAbout.styled';
import {
  DEFAULT_FROM_LINKEDIN,
  DEFAULT_HAS_TECHNOLOGIES,
  LEVEL_NOT_DEFINED
} from './ClassicCVLangs.constants';
import { StyledLanguages } from './ClassicCVLangs.styled';
import { ClassicCVLangsProps } from './ClassicCVLangs.types';

// Component.
// // --------------------------------------------------------------------------
export const ClassicCVLangs: React.FC<ClassicCVLangsProps> = ({
  fromLinkedIn = DEFAULT_FROM_LINKEDIN,
  hasTechnologies = DEFAULT_HAS_TECHNOLOGIES,
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Guard Conditions.
  // ---------------------------------------------------------------------------
  if (!manfredite.languages || isEmpty(manfredite.languages)) {
    return null;
  }

  // Functions.
  // ---------------------------------------------------------------------------
  const renderContent = (): JSX.Element => (
    <StyledSubSection>
      <StyledTitle>{t('about.languages')}</StyledTitle>
      <StyledLanguages>
        {manfredite.languages?.map((l, i) =>
          l.level === LEVEL_NOT_DEFINED ? (
            <span key={i} className="is-not-defined">
              {l.name}
            </span>
          ) : (
            <React.Fragment key={i}>
              <span>{l.name}</span>
              <LevelLadder level={LANGUAGES_LEVELS.indexOf(l.level)} />
            </React.Fragment>
          )
        )}
      </StyledLanguages>
    </StyledSubSection>
  );

  // Render.
  // ---------------------------------------------------------------------------
  return fromLinkedIn ? (
    <ClassicCVLinkedIn firstOfColumn={!hasTechnologies} isSection>
      {renderContent()}
    </ClassicCVLinkedIn>
  ) : (
    renderContent()
  );
};
