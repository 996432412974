import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { Fragment } from 'react';
import { StyledTitle } from '../career/PdfCVCareer.styled';
import { StyledShowMore } from '../education/PdfCVEducation.styled';
import PdfCVHighlightItem from './PdfCVHighlightItem';
import { StyledSection } from './PdfCVHighlights.styled';

type PdfCVHighlightsProps = {
  publicManfredite: UIPublicManfredite;
  publicProfileUrl: string;
};

const PdfCVHighlights: React.FC<PdfCVHighlightsProps> = ({
  publicManfredite: { timeline },
  publicProfileUrl
}) => {
  const SHOW_MORE_THRESHOLD = 5;

  const { t } = useI18n('publicProfile');

  const filteredPosts = timeline?.filter((e) => e.type === 'POST');
  const filteredAwards = timeline?.filter((e) => e.type === 'AWARD');

  return (
    <StyledSection>
      {filteredAwards && filteredAwards.length > 0 && (
        <div>
          <StyledTitle>
            {t('classic.sections.highlights')}/
            <strong>{t('classic.sections.awards')}</strong>
          </StyledTitle>
          <div>
            {filteredAwards?.map((item, index) => (
              <Fragment key={index}>
                {index < SHOW_MORE_THRESHOLD && (
                  <PdfCVHighlightItem profileItem={item} />
                )}
              </Fragment>
            ))}
          </div>
          {filteredAwards && filteredAwards.length >= SHOW_MORE_THRESHOLD && (
            <StyledShowMore href={publicProfileUrl} target="_blank">
              <FontAwesomeIcon icon={faPlusCircle} />
              <span>
                {t('pdf.moreHighlights', {
                  total: filteredAwards.length - SHOW_MORE_THRESHOLD
                })}
              </span>
            </StyledShowMore>
          )}
        </div>
      )}
      {filteredPosts && filteredPosts.length > 0 && (
        <div>
          <StyledTitle>
            {t('classic.sections.highlights')}/
            <strong>{t('classic.sections.publications')}</strong>
          </StyledTitle>
          <div>
            {filteredPosts?.map((item, index) => (
              <Fragment key={index}>
                {index < SHOW_MORE_THRESHOLD && (
                  <PdfCVHighlightItem profileItem={item} />
                )}
              </Fragment>
            ))}
          </div>
          {filteredPosts && filteredPosts.length >= SHOW_MORE_THRESHOLD && (
            <StyledShowMore href={publicProfileUrl} target="_blank">
              <FontAwesomeIcon icon={faPlusCircle} />
              <span>
                {t('pdf.morePublications', {
                  total: filteredPosts.length - SHOW_MORE_THRESHOLD
                })}
              </span>
            </StyledShowMore>
          )}
        </div>
      )}
    </StyledSection>
  );
};

export default PdfCVHighlights;
