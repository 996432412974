import { useI18n } from 'hooks/useI18n';
import { useMemo } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { UseClassicTechs } from './ClassicCVTechs.types';

// Custom hooks.
// -----------------------------------------------------------------------------
export const useClassicCVTechs: UseClassicTechs = ({ manfredite }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------
  const hasTechs = useMemo(
    () => UIPublicProfileService.hasAnyTech(manfredite),
    [manfredite]
  );

  // Return.
  // ---------------------------------------------------------------------------
  return {
    hasTechs,
    t
  };
};
