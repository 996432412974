import styled from 'styled-components';
import { color, fontWeight } from 'theme';

export const StyledSection = styled.div`
  margin-top: 1.27cm;
`;

export const StyledSubSection = styled.div`
  margin-top: 6.35mm;

  h3 {
    font-size: 12pt;
    font-weight: ${fontWeight('black')};
    margin-top: 0;
    margin-bottom: 6.35mm;
    page-break-after: avoid;
  }
`;

export const StyledShowMore = styled.a`
  display: flex;
  align-items: center;
  gap: 1.06mm;
  margin-bottom: 1.27cm;

  span {
    color: ${color('text_default')};
    font-size: 7.5pt;
    line-height: 1;
  }

  svg {
    width: 4.23mm !important;
    height: 4.23mm;
    color: ${color('emerald_600')};
  }
`;
