import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import PublicProfileCard from '../PublicProfileCard';
import { StyledTimeline } from './PublicProfileTimeline.styled';

type PublicProfileTimelineProps = {
  publicManfredite: UIPublicManfredite;
};

const PublicProfileTimeline: React.FC<PublicProfileTimelineProps> = ({
  publicManfredite: { timeline }
}) => {
  return (
    <StyledTimeline
      role="tabpanel"
      id="story-tabpanel"
      aria-labelledby="story-tab"
    >
      {timeline?.map((p, i) => (
        <div key={i}>
          <PublicProfileCard profileItem={p} index={i} />
        </div>
      ))}
    </StyledTimeline>
  );
};

export default PublicProfileTimeline;
