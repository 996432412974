import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { StyledSection, StyledTitle } from '../PublicProfileClassic.styled';
import ClasicCVCertificationsCard from './ClasicCVCertificationsCard';

type ClassicCVCertificationsProps = {
  publicManfredite: UIPublicManfredite;
};

const ClassicCVCertifications: React.FC<ClassicCVCertificationsProps> = ({
  publicManfredite: { timeline }
}) => {
  const { t } = useI18n('publicProfile');

  const filteredTimeline = timeline?.filter(
    (e) => e.cardType === 'certification'
  );

  return (
    <StyledSection>
      <StyledTitle>{t('classic.sections.certifications')}</StyledTitle>
      {filteredTimeline?.map((item, index) => (
        <div key={index}>
          <ClasicCVCertificationsCard profileItem={item} index={index} />
        </div>
      ))}
    </StyledSection>
  );
};

export default ClassicCVCertifications;
