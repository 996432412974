import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { ReactNode } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear } from 'utils/date';
import {
  StyledCard,
  StyledCompany,
  StyledDate,
  StyledDateRange,
  StyledEllapsedTime,
  StyledTitle
} from './PdfCVCourseCard.styled';

type PdfCVCourseCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
};

const PdfCVCourseCard = ({ profileItem }: PdfCVCourseCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  function renderEllapsedTime(from: string, to: string): ReactNode {
    const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

    if (!ellapsedTime) {
      return null;
    }

    return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
  }

  return (
    <StyledCard>
      <StyledTitle>
        {profileItem.title}
        {profileItem.link && profileItem.company && (
          <>
            ,{' '}
            <StyledCompany href={profileItem.link}>
              {profileItem.company}
            </StyledCompany>
          </>
        )}
        {!profileItem.link && profileItem.company && (
          <>
            , <StyledCompany as="span">{profileItem.company}</StyledCompany>
          </>
        )}
      </StyledTitle>

      {(profileItem.dateInitial || profileItem.dateSingle) && (
        <StyledDate>
          {profileItem.dateInitial && profileItem.dateFinal && (
            <>
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                {profileItem.dateFinal && (
                  <span>
                    {formatDateMonthYear(profileItem.dateFinal, locale)}
                  </span>
                )}
              </StyledDateRange>
              {renderEllapsedTime(
                profileItem.dateInitial.toString(),
                profileItem.dateFinal.toString()
              )}
            </>
          )}

          {profileItem.dateInitial && !profileItem.dateFinal && (
            <>
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                <span>{t('timeline.today')}</span>
              </StyledDateRange>
              {renderEllapsedTime(
                profileItem.dateInitial.toString(),
                new Date().toString()
              )}
            </>
          )}
        </StyledDate>
      )}
    </StyledCard>
  );
};

export default PdfCVCourseCard;
