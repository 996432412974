import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import {
  DEFAULT_LINKEDIN_IMPORT,
  DEFAULT_PROFILE_DATA,
  QS_AVATAR,
  QS_EDUCATIONS,
  QS_INTRODUCTION,
  QS_JOBS,
  QS_LANGUAGES,
  QS_NAME,
  QS_POSITION,
  QS_SKILLS,
  QS_WHERE_DO_YOU_LIVE
} from './PublicProfileClassic.constants';
import {
  ClassicCVSection,
  HasProfileData,
  LinkedInImport,
  UsePublicProfileClassic
} from './PublicProfileClassic.types';
import { hasBookmarks, hasCardType } from './PublicProfileClassic.utils';

// Custom Hook.
// -----------------------------------------------------------------------------
export const usePublicProfileClassic: UsePublicProfileClassic = ({
  manfredite,
  setHasLinkedInQuery
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const [displayNavBar, setDisplayNavBar] = useState(false);
  const [displayTabs, setDisplayTabs] = useState(false);
  const [hasData, setHasData] = useState<HasProfileData>(DEFAULT_PROFILE_DATA);
  const [linkedIn, setLinkedIn] = useState<LinkedInImport>(
    DEFAULT_LINKEDIN_IMPORT
  );
  const [sections, setSections] = useState<ClassicCVSection[]>([]);
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();
  const { query } = useRouter();

  // Handlers.
  // ---------------------------------------------------------------------------
  const handleOnDisplayMenu = useCallback(() => {
    if (displayTabs === false) {
      document.querySelector('body')?.classList.add('mobile-scroll-blocked');
    } else {
      document.querySelector('body')?.classList.remove('mobile-scroll-blocked');
    }
    setDisplayTabs(!displayTabs);
  }, [displayTabs]);

  const handleOnCloseMenu = useCallback(() => {
    setDisplayTabs(false);
    document.querySelector('body')?.classList.remove('mobile-scroll-blocked');
  }, []);

  const handleResetLinkedIn = useCallback(() => {
    if (setHasLinkedInQuery) {
      setHasLinkedInQuery(false);
    }
    setLinkedIn(DEFAULT_LINKEDIN_IMPORT);
  }, [setHasLinkedInQuery]);

  // Effects.
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (query.linkedin) {
      const newLinkedIn: LinkedInImport = {
        avatar: query.linkedin.includes(QS_AVATAR),
        introduction: query.linkedin.includes(QS_INTRODUCTION),
        jobs: query.linkedin.includes(QS_JOBS),
        languages: query.linkedin.includes(QS_LANGUAGES),
        name: query.linkedin.includes(QS_NAME),
        position: query.linkedin.includes(QS_POSITION),
        technologies: query.linkedin.includes(QS_SKILLS),
        educations: query.linkedin.includes(QS_EDUCATIONS),
        whereDoYouLive: query.linkedin.includes(QS_WHERE_DO_YOU_LIVE)
      };
      setLinkedIn(newLinkedIn);
    }
  }, [query.linkedin]);

  // Profile data.
  useEffect(() => {
    setHasData({
      bookmarks: hasBookmarks(manfredite),
      career: hasCardType(manfredite, 'career'),
      certifications: hasCardType(manfredite, 'certification'),
      education: hasCardType(manfredite, 'education'),
      highlights: hasCardType(manfredite, 'highlight'),
      projects: hasCardType(manfredite, 'project')
    });
  }, [manfredite]);

  // Sections.
  useEffect(() => {
    const newSections: ClassicCVSection[] = [
      { section: 'about', tooltip: t('classic.sections.aboutMe') }
    ];

    if (hasData) {
      // Bookmarks.
      if (hasData.bookmarks) {
        newSections.push({
          section: 'bookmarks',
          tooltip: t('classic.sections.bookmarks')
        });
      }

      // Career.
      if (hasData.career) {
        newSections.push({
          section: 'career',
          tooltip: t('classic.sections.career')
        });
      }

      // Certifications.
      if (hasData.certifications) {
        newSections.push({
          section: 'certifications',
          tooltip: t('classic.sections.certifications')
        });
      }

      // Education.
      if (hasData.education) {
        newSections.push({
          section: 'education',
          tooltip: t('classic.sections.education')
        });
      }

      // Highlights.
      if (hasData.highlights) {
        newSections.push({
          section: 'highlights',
          tooltip: t('classic.sections.highlights')
        });
      }

      // Projects.
      if (hasData.projects) {
        newSections.push({
          section: 'projects',
          tooltip: t('classic.sections.projects')
        });
      }
    }

    setSections(newSections);
  }, [hasData, t]);

  // Display nav bar.
  useEffect(() => {
    const handleScroll = (): void => {
      if (window.scrollY > 100) {
        setDisplayNavBar(true);
      } else {
        setDisplayNavBar(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Return.
  // ---------------------------------------------------------------------------
  return {
    displayNavBar,
    displayTabs,
    hasData,
    hasLinkedIn: Object.values(linkedIn).some((value) => value),
    linkedIn,
    locale,
    sections,
    onCloseMenu: handleOnCloseMenu,
    onDisplayMenu: handleOnDisplayMenu,
    onResetLinkedIn: handleResetLinkedIn,
    t
  };
};
