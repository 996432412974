import ClasicCVCard from '../ClasicCVCard';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import { StyledSection, StyledTitle } from '../PublicProfileClassic.styled';
import { DEFAULT_FROM_LINKENDIN } from './ClassicCVCareer.constants';
import { useClassicCVCareer } from './ClassicCVCareer.hooks';
import { ClassicCVCareerProps } from './ClassicCVCareer.types';

// Component.
// -----------------------------------------------------------------------------
const ClassicCVCareer: React.FC<ClassicCVCareerProps> = ({
  fromLinkedIn = DEFAULT_FROM_LINKENDIN,
  publicManfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t, timeline } = useClassicCVCareer({ publicManfredite });

  // Functions.
  // ---------------------------------------------------------------------------
  const renderCareer = (): JSX.Element => (
    <>
      <StyledTitle>{t('classic.sections.career')}</StyledTitle>
      {timeline?.map((p, i) => (
        <div key={i}>
          <ClasicCVCard profileItem={p} index={i} />
        </div>
      ))}
    </>
  );

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledSection>
      {fromLinkedIn.jobs ? (
        <ClassicCVLinkedIn isSection>{renderCareer()}</ClassicCVLinkedIn>
      ) : (
        renderCareer()
      )}
    </StyledSection>
  );
};

export default ClassicCVCareer;
