import styled from 'styled-components';
import { color, fontWeight } from '../../../../theme';

export const StyledCard = styled.div`
  margin-bottom: 4.23mm;
  padding-left: 6.36mm;
  page-break-inside: avoid;
`;

export const StyledCompany = styled.a`
  font-weight: ${fontWeight('medium')};
`;

export const StyledTitle = styled.h4`
  font-size: 9pt;
  line-height: 1.33;
  font-weight: ${fontWeight('extraBold')};
  margin: 0;
  position: relative;

  &::before {
    content: '';
    width: 4.23mm;
    height: 0;
    border-top: 0.26mm solid ${color('midnight_200')};
    position: absolute;
    left: -6.36mm;
    top: 2.21mm;
  }
`;

export const StyledDate = styled.div`
  margin-top: 1.06mm;
  font-size: 7.5pt;
  line-height: 1.14;
  font-weight: ${fontWeight('extraBold')};
  display: flex;
  flex-wrap: wrap;
  gap: 1.06mm;
`;

export const StyledDateRange = styled.div`
  > :first-child::after {
    content: ' — ';
    color: ${color('text_default')};
  }
`;

export const StyledEllapsedTime = styled.span`
  font-weight: ${fontWeight('medium')};
  white-space: nowrap;
`;
