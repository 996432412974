import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import PdfCVCard from '../PdfCVCard';
import { StyledSection, StyledTitle } from './PdfCVCareer.styled';

type PdfCVCareerProps = {
  publicManfredite: UIPublicManfredite;
};

const PdfCVCareer: React.FC<PdfCVCareerProps> = ({
  publicManfredite: { timeline }
}) => {
  const { t } = useI18n('publicProfile');
  const filteredTimeline = timeline?.filter((e) => e.cardType === 'career');

  return (
    <StyledSection>
      <StyledTitle>
        {t('classic.sections.experience')}/
        <strong>{t('classic.sections.career')}</strong>
      </StyledTitle>

      {filteredTimeline?.map((p, i) => (
        <PdfCVCard key={i} profileItem={p} />
      ))}
    </StyledSection>
  );
};

export default PdfCVCareer;
