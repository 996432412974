import {
  faGithub,
  faLinkedin,
  faStackOverflow,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faLink,
  faMapMarkerCheck,
  faPhone
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/shared/Avatar';
import LevelLadder from 'components/shared/levelLadder/LevelLadder';
import { useI18n } from 'hooks/useI18n';
import {
  UIPublicManfredite,
  UIPublicManfrediteLink
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { LANGUAGES_LEVELS } from 'model';
import { ReactNode } from 'react';
import { getPublicPath } from 'utils/paths';
import { StyledTitle } from './about-me/PdfCVAbout.styled';
import {
  StyledData,
  StyledDataItem,
  StyledHeader,
  StyledIntro,
  StyledLanguages,
  StyledLanguagesList,
  StyledName,
  StyledSectionTitle
} from './PdfCVHeader.styled';

type PdfCVHeaderProps = {
  publicManfredite: UIPublicManfredite;
  publicProfileUrl: string;
  hasPublicProfile: boolean;
};

const PdfCVHeader: React.FC<PdfCVHeaderProps> = ({
  hasPublicProfile = false,
  publicProfileUrl,
  publicManfredite: {
    avatarUrl,
    avatarAlt,
    firstName,
    lastName,
    headline,
    whereDoYouLive,
    links,
    email,
    phoneNumber,
    languages
  }
}) => {
  const { t } = useI18n('publicProfile');

  const renderLinks = (links: UIPublicManfrediteLink[]): ReactNode => {
    const icons: { [key: string]: any } = {
      twitter: faTwitter,
      github: faGithub,
      stackOverflow: faStackOverflow,
      linkedIn: faLinkedin,
      other: faLink
    };

    const trimmedUrl = (url: string, type: string): string | undefined => {
      if (type === 'other') {
        return url.replace(/\/$/, '').replace(/^https?:\/\//, '');
      } else {
        return url.replace(/\/$/, '').split('/').pop();
      }
    };

    return (
      <StyledData>
        <StyledSectionTitle>{t('classic.sections.myLinks')}</StyledSectionTitle>
        {links.map((l, i) => (
          <StyledDataItem
            as="a"
            key={`link_${i}`}
            href={l.url}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={icons[l.type]} />
            <span>{trimmedUrl(l.url, l.type)}</span>
          </StyledDataItem>
        ))}

        {hasPublicProfile && (
          <StyledDataItem as="a" href={publicProfileUrl}>
            <img
              src={getPublicPath('/images/isotype-manfred-green.svg')}
              alt=""
            />
            <span>{trimmedUrl(publicProfileUrl, 'other')}</span>
          </StyledDataItem>
        )}
      </StyledData>
    );
  };
  return (
    <StyledHeader>
      {avatarUrl && (
        <Avatar avatarImg={avatarUrl} avatarAlt={avatarAlt || ''} />
      )}

      <StyledName>
        {(firstName || lastName) && (
          <h1>
            {firstName} {lastName}
          </h1>
        )}

        {!firstName && !lastName && <h1>{t('unknownManfredite')}</h1>}

        {headline && <StyledIntro>{headline}</StyledIntro>}
      </StyledName>

      {(email || phoneNumber || whereDoYouLive) && (
        <StyledData>
          <StyledSectionTitle>
            {t('classic.sections.contact')}
          </StyledSectionTitle>
          {email && (
            <StyledDataItem>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${email}`}>{email}</a>
            </StyledDataItem>
          )}
          {phoneNumber && (
            <StyledDataItem>
              <FontAwesomeIcon icon={faPhone} />
              <span>{phoneNumber}</span>
            </StyledDataItem>
          )}
          {whereDoYouLive && (
            <StyledDataItem>
              <FontAwesomeIcon icon={faMapMarkerCheck} />
              <span>{whereDoYouLive}</span>
            </StyledDataItem>
          )}
        </StyledData>
      )}

      {links && renderLinks(links)}

      {languages && languages.length > 0 && (
        <StyledLanguages>
          <StyledTitle>{t('about.languages')}</StyledTitle>
          <StyledLanguagesList>
            {languages.map((l, i) => (
              <span key={i}>
                <span>{l.name}</span>
                {l.level !== 'not-defined' && (
                  <LevelLadder
                    level={LANGUAGES_LEVELS.indexOf(l.level)}
                    useImage
                  />
                )}
              </span>
            ))}
          </StyledLanguagesList>
        </StyledLanguages>
      )}
    </StyledHeader>
  );
};

export default PdfCVHeader;
