import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { isEmpty } from 'lodash';
import { CardType } from './PublicProfileClassic.types';

// Utils.
// -----------------------------------------------------------------------------
export const hasBookmarks = (manfredite: UIPublicManfredite): boolean =>
  !isEmpty(manfredite.bookmarks);

export const hasCardType = (
  manfredite: UIPublicManfredite,
  card: CardType
): boolean =>
  manfredite.timeline
    ? manfredite.timeline.some((time) => time.cardType === card)
    : false;
