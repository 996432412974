import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear } from 'utils/date';
import { getPublicPath } from 'utils/paths';
import {
  StyledDate,
  StyledDateRange,
  StyledDesc,
  StyledEllapsedTime,
  StyledTechsItem,
  StyledTechsList,
  StyledTechsLogo,
  StyledTechsTitle,
  StyledTrigger,
  StyledTriggerIcon
} from '../ClasicCVCard.styled';
import {
  StyledCard,
  StyledCompany,
  StyledContent,
  StyledExtraContent,
  StyledLogo,
  StyledTitle
} from './ClasicCVCertificationsCard.styled';

type ClasicCVCertificationsCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
  index: number;
};

const ClasicCVCertificationsCard = ({
  profileItem,
  index
}: ClasicCVCertificationsCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();
  const content = useRef<HTMLDivElement>(null);
  const currentHeight = content.current?.scrollHeight;

  const [setActive, setActiveState] = useState('');

  function toggleContent(): void {
    setActiveState(setActive === '' ? 'is-active' : '');
  }

  const contentHeight = {
    '--content-height': `${currentHeight}px`
  } as React.CSSProperties;

  useEffect(() => {
    if (index === 0) {
      setActiveState('is-active');
    }
  }, [index]);

  function renderEllapsedTime(from: string, to: string): ReactNode {
    const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

    if (!ellapsedTime) {
      return null;
    }

    return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
  }

  return (
    <StyledCard>
      <StyledLogo className={`${!profileItem.logoUrl ? 'no-logo' : ''}`}>
        {profileItem.logoUrl && (
          <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
        )}
        {!profileItem.logoUrl && (
          <img
            src={getPublicPath('/images/profile/certification.svg')}
            alt=""
          />
        )}
      </StyledLogo>
      <StyledContent>
        {(profileItem.desc ||
          (profileItem.techs && profileItem.techs.length > 0)) && (
          <StyledTrigger
            type="button"
            id={'button_' + profileItem.cardType + index}
            className={`${setActive}`}
            onClick={toggleContent}
            aria-expanded={setActive === 'is-active'}
            aria-controls={'content_' + profileItem.cardType + index}
          >
            <div>
              {profileItem.link && (
                <StyledTitle as="a" href={profileItem.link}>
                  {profileItem.title}
                </StyledTitle>
              )}
              {!profileItem.link && (
                <StyledTitle>{profileItem.title}</StyledTitle>
              )}
            </div>
            <StyledTriggerIcon>
              <FontAwesomeIcon icon={faAngleDown} />
            </StyledTriggerIcon>
          </StyledTrigger>
        )}
        {!profileItem.desc &&
          profileItem.techs &&
          profileItem.techs.length === 0 && (
            <div>
              <StyledTitle>{profileItem.title}</StyledTitle>
            </div>
          )}
        {profileItem.company && (
          <StyledCompany>
            {t('issuedBy', { company: profileItem.company })}
          </StyledCompany>
        )}

        {(profileItem.dateInitial || profileItem.dateSingle) && (
          <StyledDate>
            <div>
              {profileItem.dateInitial && profileItem.dateFinal && (
                <>
                  <StyledDateRange>
                    <span>
                      {formatDateMonthYear(profileItem.dateInitial, locale)}
                    </span>
                    {profileItem.dateFinal && (
                      <span>
                        {formatDateMonthYear(profileItem.dateFinal, locale)}
                      </span>
                    )}
                  </StyledDateRange>

                  {renderEllapsedTime(
                    profileItem.dateInitial.toString(),
                    profileItem.dateFinal.toString()
                  )}
                </>
              )}

              {profileItem.dateInitial && !profileItem.dateFinal && (
                <StyledDateRange>
                  <span>
                    {formatDateMonthYear(profileItem.dateInitial, locale)}
                  </span>
                  <span>{t('classic.noExpiryDate')}</span>
                </StyledDateRange>
              )}
            </div>
          </StyledDate>
        )}

        <StyledExtraContent
          ref={content}
          id={'content_' + profileItem.cardType + index}
          className={`${setActive}`}
          style={contentHeight}
          role="region"
          aria-labelledby={'button_' + profileItem.cardType + index}
        >
          {profileItem.desc && (
            <StyledDesc>
              <MarkdownComponent content={profileItem.desc} />
            </StyledDesc>
          )}

          {profileItem.techs && profileItem.techs.length > 0 && (
            <div>
              <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
              <StyledTechsList>
                {profileItem.techs?.map((t, i) => (
                  <StyledTechsItem key={i}>
                    <StyledTechsLogo>
                      <img src={t.iconUrl} alt={t.iconAlt} />
                    </StyledTechsLogo>
                    <span>{t.name}</span>
                  </StyledTechsItem>
                ))}
              </StyledTechsList>
            </div>
          )}
        </StyledExtraContent>
      </StyledContent>
    </StyledCard>
  );
};

export default ClasicCVCertificationsCard;
