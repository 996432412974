import { isEmpty } from 'lodash';
import { StyledSection, StyledTitle } from '../PublicProfileClassic.styled';
import { ClassicCVProjectCard } from './ClassicCVProjectCard';
import { useClassicCVProjects } from './ClassicCVProjects.hooks';
import { ClassicCVProjectsProps } from './ClassicCVProjects.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVProjects: React.FC<ClassicCVProjectsProps> = ({
  publicManfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { projects, t } = useClassicCVProjects({ publicManfredite });

  // Guard clause.
  // ---------------------------------------------------------------------------
  if (isEmpty(projects)) {
    return null;
  }

  // Render
  // ---------------------------------------------------------------------------
  return (
    <StyledSection>
      <StyledTitle>{t('classic.sections.projects')}</StyledTitle>
      {projects.map((item, index) => (
        <div key={index}>
          <ClassicCVProjectCard profileItem={item} />
        </div>
      ))}
    </StyledSection>
  );
};
