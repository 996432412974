import { isEmpty } from 'lodash';
import ClasicCVCard from '../ClasicCVCard';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import { StyledSection, StyledTitle } from '../PublicProfileClassic.styled';
import { DEFAULT_FROM_LINKENDIN } from './ClassicCVEducation.constants';
import { useClassicCVEducation } from './ClassicCVEducation.hooks';
import { StyledSubSection } from './ClassicCVEducation.styled';
import { ClassicCVEducationProps } from './ClassicCVEducation.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVEducation: React.FC<ClassicCVEducationProps> = ({
  fromLinkedIn = DEFAULT_FROM_LINKENDIN,
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { courses, degrees, trainings, hasData, t } = useClassicCVEducation({
    manfredite
  });

  // Guard clause.
  // ---------------------------------------------------------------------------
  if (!hasData) {
    return null;
  }

  const renderEducations = (): JSX.Element => (
    <>
      <StyledTitle>{t('classic.sections.education')}</StyledTitle>

      {!isEmpty(degrees) && (
        <StyledSubSection>
          <StyledTitle as="h3">{t('classic.sections.degrees')}</StyledTitle>
          {degrees.map((item, index) => (
            <div key={index}>
              <ClasicCVCard profileItem={item} index={index} />
            </div>
          ))}
        </StyledSubSection>
      )}

      {!isEmpty(courses) && (
        <StyledSubSection>
          <StyledTitle as="h3">{t('classic.sections.courses')}</StyledTitle>
          {courses.map((item, index) => (
            <div key={index}>
              <ClasicCVCard profileItem={item} index={index} />
            </div>
          ))}
        </StyledSubSection>
      )}

      {!isEmpty(trainings) && (
        <StyledSubSection>
          <StyledTitle as="h3">
            {t('classic.sections.selfTraining')}
          </StyledTitle>
          {trainings.map((item, index) => (
            <div key={index}>
              <ClasicCVCard profileItem={item} index={index} />
            </div>
          ))}
        </StyledSubSection>
      )}
    </>
  );
  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledSection>
      {fromLinkedIn.educations ? (
        <ClassicCVLinkedIn isSection>{renderEducations()}</ClassicCVLinkedIn>
      ) : (
        renderEducations()
      )}
    </StyledSection>
  );
};
