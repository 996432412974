import styled from 'styled-components';
import { color, fontSize, fontWeight, spacing } from 'theme';

export const StyledFlag = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${spacing('xs')};
  color: ${color('white')};
  font-size: ${fontSize('xs')};
  line-height: 1;
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  padding: ${spacing('xs')} ${spacing('lg')} ${spacing('xs')} ${spacing('md')};
  border-left: ${spacing('xs2')} solid ${color('midnight_800')};
  margin-left: -${spacing('xs2')};
  clip-path: polygon(
    0 0,
    100% 0,
    calc(100% - ${spacing('xs')}) 50%,
    100% 100%,
    0 100%
  );

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
  }

  &.without_icon {
    padding: ${spacing('xs2')} ${spacing('md')} ${spacing('xs2')}
      ${spacing('xs')};
    font-size: ${fontSize('xs2')};
  }

  &.set2_04 {
    background-color: ${color('set2_04')};
  }

  &.set1_05 {
    background-color: ${color('set1_05')};
  }

  &.set1_03 {
    background-color: ${color('set1_03')};
  }

  &.set1_01 {
    background-color: ${color('set1_01')};
  }

  &.set1_04 {
    background-color: ${color('set1_04')};
  }

  &.set1_02 {
    background-color: ${color('set1_02')};
  }

  &.set1_07 {
    background-color: ${color('set1_07')};
  }

  &.set1_09 {
    background-color: ${color('set1_09')};
  }

  &.set3_04 {
    background-color: ${color('set3_04')};
  }

  &.set3_10 {
    background-color: ${color('set3_10')};
  }

  &.set3_05 {
    background-color: ${color('set3_05')};
  }

  &.set3_01 {
    background-color: ${color('set3_01')};
  }

  &.pastel1_01 {
    background-color: ${color('pastel1_01')};
    color: ${color('text_default')};
  }

  &.pastel1_02 {
    background-color: ${color('pastel1_02')};
    color: ${color('text_default')};
  }

  &.pastel1_03 {
    background-color: ${color('pastel1_03')};
    color: ${color('text_default')};
  }

  &.pastel1_04 {
    background-color: ${color('pastel1_04')};
    color: ${color('text_default')};
  }

  &.pastel1_05 {
    background-color: ${color('pastel1_05')};
    color: ${color('text_default')};
  }

  &.AWARD {
    background-color: ${color('set1_07')};
  }

  &.pastel1_06 {
    background-color: ${color('pastel1_06')};
    color: ${color('text_default')};
  }

  &.dark2_03 {
    background-color: ${color('dark2_03')};
  }

  &.set3_06 {
    background-color: ${color('set3_06')};
  }

  &.positive {
    background-color: ${color('positive_500')};
    color: ${color('positive_900')};
  }

  &.negative {
    background-color: ${color('negative_500')};
  }
`;
