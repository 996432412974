import { useI18n } from 'hooks/useI18n';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseClassicCVAboutMarkdownContent } from './ClassicCVAboutMarkdownContent.types';

// Custom hooks.
// -----------------------------------------------------------------------------
export const useClassicCVAboutMarkdownContent: UseClassicCVAboutMarkdownContent =
  () => {
    // Hooks.
    // ---------------------------------------------------------------------------
    const [displayReadMore, setdisplayReadMore] = useState(false);
    const [showAllText, setShowAllText] = useState(false);
    const { t } = useI18n('publicProfile');
    const clampableText = useRef<HTMLDivElement>(null);

    // Functions.
    // ---------------------------------------------------------------------------
    const checkLineClamp = useCallback(() => {
      if (
        clampableText.current &&
        clampableText.current.clientHeight < clampableText.current.scrollHeight
      ) {
        setdisplayReadMore(true);
      } else {
        setdisplayReadMore(false);
      }
    }, []);

    // Handlers.
    // ---------------------------------------------------------------------------
    const handleSetAllText = useCallback(() => {
      setShowAllText(!showAllText);
    }, [showAllText]);

    // Effects.
    // ---------------------------------------------------------------------------
    useEffect(() => {
      checkLineClamp();
    }, [checkLineClamp]);

    // Return.
    // ---------------------------------------------------------------------------
    return {
      clampableText,
      displayReadMore,
      onSetAllText: handleSetAllText,
      showAllText,
      t
    };
  };
