import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { Fragment } from 'react';
import { StyledTitle } from '../career/PdfCVCareer.styled';
import PdfCVCard from '../PdfCVCard';
import PdfCVCertificationsCard from './PdfCVCertificationsCard';
import PdfCVCourseCard from './PdfCVCourseCard';
import {
  StyledSection,
  StyledShowMore,
  StyledSubSection
} from './PdfCVEducation.styled';

type PdfCVEducationProps = {
  publicManfredite: UIPublicManfredite;
  publicProfileUrl: string;
};

const PdfCVEducation: React.FC<PdfCVEducationProps> = ({
  publicManfredite: { timeline },
  publicProfileUrl
}) => {
  const { t } = useI18n('publicProfile');

  const SHOW_MORE_THRESHOLD = 5;

  const totalEducationShowMore = SHOW_MORE_THRESHOLD * 2;

  const filteredDegrees = timeline?.filter((e) => e.type === 'DEGREE');
  const filteredCoursesTraining = timeline?.filter(
    (e) => e.type === 'COURSE' || e.type === 'SELF_TRAINING'
  );
  const filteredCertifications = timeline?.filter(
    (e) => e.cardType === 'certification'
  );
  const totalFilteredEducation = filteredDegrees?.concat(
    filteredCoursesTraining || []
  );

  return (
    <StyledSection>
      <StyledTitle>
        {t('classic.sections.education')}/
        <strong>{t('classic.sections.degrees')}</strong>
      </StyledTitle>
      {filteredDegrees && filteredDegrees?.length > 0 && (
        <StyledSubSection>
          <h3>{t('classic.sections.degrees')}</h3>
          {filteredDegrees?.map((item, index) => (
            <Fragment key={index}>
              {index < SHOW_MORE_THRESHOLD && <PdfCVCard profileItem={item} />}
            </Fragment>
          ))}
        </StyledSubSection>
      )}
      {filteredCoursesTraining && filteredCoursesTraining?.length > 0 && (
        <StyledSubSection>
          <h3>
            {t('classic.sections.courses')} &{' '}
            {t('classic.sections.selfTraining')}
          </h3>
          {filteredCoursesTraining?.map((item, index) => (
            <Fragment key={index}>
              {index < SHOW_MORE_THRESHOLD && (
                <PdfCVCourseCard profileItem={item} />
              )}
            </Fragment>
          ))}
        </StyledSubSection>
      )}
      {totalFilteredEducation &&
        totalFilteredEducation.length >= totalEducationShowMore && (
          <StyledShowMore href={publicProfileUrl} target="_blank">
            <FontAwesomeIcon icon={faPlusCircle} />
            <span>
              {t('pdf.moreEducations', {
                total: totalFilteredEducation.length - totalEducationShowMore
              })}
            </span>
          </StyledShowMore>
        )}
      {filteredCertifications && filteredCertifications?.length > 0 && (
        <StyledSubSection>
          <h3>{t('classic.sections.certifications')}</h3>
          {filteredCertifications?.map((item, index) => (
            <Fragment key={index}>
              {index < SHOW_MORE_THRESHOLD && (
                <PdfCVCertificationsCard profileItem={item} />
              )}
            </Fragment>
          ))}
        </StyledSubSection>
      )}
      {filteredCertifications &&
        filteredCertifications.length >= SHOW_MORE_THRESHOLD && (
          <StyledShowMore href={publicProfileUrl} target="_blank">
            <FontAwesomeIcon icon={faPlusCircle} />
            <span>
              {t('pdf.moreCertifications', {
                total: filteredCertifications.length - SHOW_MORE_THRESHOLD
              })}
            </span>
          </StyledShowMore>
        )}
    </StyledSection>
  );
};

export default PdfCVEducation;
