import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  screen,
  shadow,
  spacing,
  zindex
} from 'theme';

export const StyledMobileFilters = styled.button`
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-top: 1px solid ${color('midnight_200')};
  border-bottom: 1px solid ${color('midnight_200')};
  margin-bottom: ${spacing('xl')};
  padding-top: ${spacing('md')};
  padding-bottom: ${spacing('md')};
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  font-size: ${fontSize('xs')};
  line-height: 1;
  text-transform: uppercase;
  font-weight: ${fontWeight('extraBold')};

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
    color: ${color('midnight_300')};
  }

  @media (min-width: ${screen('md')}) {
    display: none;
  }
`;

export const StyledFilters = styled.div`
  margin-top: ${spacing('xl')};
  border-top: 1px solid ${color('midnight_200')};
  border-bottom: 1px solid ${color('midnight_200')};
  padding-top: ${spacing('md')};
  padding-bottom: ${spacing('md')};

  @media (max-width: ${screen('mdMax')}) {
    display: none;
    position: fixed;
    inset: 0;
    background-color: ${color('white')};
    padding: 0 ${spacing('lg')} ${spacing('md')};
    margin: ${spacing('md')};
    border: 0;
    box-shadow: ${shadow('sm')};
    z-index: ${zindex('filters')};
    overflow: hidden;
    flex-direction: column;

    &.is-open {
      display: flex;
    }
  }

  strong {
    font-size: ${fontSize('extraBold')};
  }
`;

export const StyledFiltersHeader = styled.div`
  background-color: ${color('midnight_100')};
  padding: ${spacing('md')} ${spacing('lg')};
  margin: 0 -${spacing('lg')};
  font-size: ${fontSize('lg')};
  line-height: 1;
  font-weight: ${fontWeight('black')};
  display: flex;
  align-items: center;

  @media (min-width: ${screen('md')}) {
    display: none;
  }

  button {
    flex-shrink: 0;
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      width: ${spacing('lg')} !important;
      height: ${spacing('lg')};
      color: ${color('midnight_300')};
    }
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  overflow: auto;
  padding-top: ${spacing('xl')};
  flex-grow: 1;

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
    padding-top: 0;
  }
`;

export const StyledFilterList = styled.div`
  display: flex;
  gap: ${spacing('md')};

  @media (max-width: ${screen('mdMax')}) {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    color: ${color('link')};
    font-weight: ${fontWeight('medium')};
    cursor: pointer;
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked + label {
      color: inherit;
      cursor: default;
      border-bottom: 1px solid;
    }
  }
`;
