import { StyledFlag } from 'components/shared/BadgeFlag.styled';
import { ButtonLink } from 'components/shared/ButtonLink.styled';
import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  shadow,
  spacing
} from 'theme';

export const StyledCard = styled.div`
  background-color: ${color('white')};
  padding: ${spacing('xs')} ${spacing('lg')} ${spacing('lg')};
  border-radius: ${radius('lg')};
  box-shadow: ${shadow('md')};
  position: relative;
  max-width: 22rem;
  margin: auto;

  @media (max-width: ${screen('mdMax')}) {
    margin: unset;
  }

  ${StyledFlag} {
    margin-top: -${spacing('xl')};
    margin-left: calc(-${spacing('lg')} - ${spacing('xs2')});
    transform: translateY(-50%);
  }
`;

export const StyledCardIcon = styled.div`
  position: absolute;
  top: 3rem;
  width: ${spacing('xl4')};
  height: ${spacing('xl4')};
  border-radius: 50%;
  border: 1px solid ${color('sand_200')};
  background-color: ${color('white')};
  box-shadow: ${shadow('md')};
  color: ${color('midnight_400')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.25rem !important;
    height: 1.25rem;
  }

  &::before {
    content: '';
    display: block;
    width: 2.5rem;
    height: 1px;
    background-color: ${color('sand_200')};
    position: absolute;
    top: 50%;
  }

  @media (max-width: ${screen('mdMax')}) {
    display: none;
  }
`;

export const StyledLogo = styled.img`
  max-height: ${spacing('xl4')};
  display: block;
`;

export const StyledTitle = styled.h3`
  font-size: ${fontSize('lg')};
  line-height: 1.16;
  font-weight: ${fontWeight('extraBold')};
  margin-top: ${spacing('sm')};
  margin-bottom: ${spacing('xs2')};
`;

export const StyledLink = styled.div`
  width: 100%;
  border-top: 1px solid ${color('midnight_200')};
  padding-top: ${spacing('xs2')};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('sm')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledTriggerIcon = styled.div`
  margin-left: auto;
  padding-left: ${spacing('lg')};
  color: ${color('midnight_300')};

  svg {
    width: ${spacing('lg')} !important;
    height: ${spacing('lg')};
  }
`;

export const StyledTrigger = styled.button`
  -webkit-appearance: none;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-top: ${spacing('lg')};
  text-align: left;
  font-family: inherit;

  color: inherit;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${color('link_hover')};
  }

  &:active {
    color: ${color('link_active')};
  }

  &.is-active {
    ${StyledTriggerIcon} svg {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  > h4 {
    margin: 0;
    font-size: ${fontSize('lg')};
    line-height: 1.2;
    font-weight: ${fontWeight('medium')};
  }
`;

export const StyledDate = styled.div`
  margin-top: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
    line-height: 1.25;
  }
`;

export const StyledDateRange = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  font-weight: ${fontWeight('extraBold')};

  > * {
    display: flex;
    align-items: center;
  }

  > :first-child::after {
    content: '';
    width: ${spacing('md')};
    height: 1px;
    background-color: ${color('midnight_800')};
    margin-left: ${spacing('xs')};
    margin-right: ${spacing('xs')};
  }
`;

export const StyledEllapsedTime = styled.p`
  color: ${color('text_subtle')};
  margin-top: ${spacing('xs')};
  margin-bottom: 0;
  font-weight: ${fontWeight('medium')};
  white-space: nowrap;
`;

export const StyledContent = styled.div`
  overflow: hidden;
  margin: 0;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;

  &.is-active {
    max-height: var(--content-height);
  }

  ${StyledLink} {
    margin-top: ${spacing('xl')};
    border-top: 0;
    padding-top: 0;
  }
`;

export const StyledDesc = styled.div`
  padding-top: ${spacing('md')};
  margin-bottom: ${spacing('md')};
  font-size: ${fontSize('sm')};
  line-height: 1.4;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
    line-height: 1.5;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-left: ${spacing('md')};
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  ~ ${ButtonLink} {
    padding-top: 0;
  }
`;

export const StyledTechs = styled.div`
  margin-top: ${spacing('xl')};
`;

export const StyledTechsTitle = styled.h5`
  font-size: ${fontSize('xs')};
  line-height: 1.14;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: ${fontWeight('extraBold')};
  margin-bottom: ${spacing('sm')};
  margin-top: 0;
`;

export const StyledTechsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('md')};
`;

export const StyledTechsItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing('xs2')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
`;

export const StyledTechsLogo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: ${spacing('xl')};

  img {
    max-width: ${spacing('xl')};
    max-height: ${spacing('xl')};
    display: block;
    filter: grayscale(100%);
    width: 100%;
  }
`;

export const StyledType = styled.div`
  color: ${color('emerald_900')};
  text-transform: uppercase;
  font-size: ${fontSize('xs')};
  line-height: 1;
`;
