import { DEFAULT_LINKEDIN_IMPORT } from './ClassicCVHeader.constants';
import { StyledHeader } from './ClassicCVHeader.styled';
import { ClassicCVHeaderProps } from './ClassicCVHeader.types';
import { ClassicCVHeaderAvatar } from './ClassicCVHeaderAvatar';
import { ClassicCVHeaderData } from './ClassicCVHeaderData';

// Component.
// -----------------------------------------------------------------------------
const ClassicCVHeader: React.FC<ClassicCVHeaderProps> = ({
  email,
  linkedIn = DEFAULT_LINKEDIN_IMPORT,
  phone,
  manfredite
}) => (
  <StyledHeader>
    <ClassicCVHeaderAvatar linkedIn={linkedIn} manfredite={manfredite} />
    <ClassicCVHeaderData
      email={email}
      linkedIn={linkedIn}
      manfredite={manfredite}
      phone={phone}
    />
  </StyledHeader>
);

export default ClassicCVHeader;
