import { FromLinkedIn } from './ClassicCVAbout.types';

// Constants.
// -----------------------------------------------------------------------------
export const DEFAULT_FROM_LINKEDIN: FromLinkedIn = {
  introduction: false,
  languages: false,
  technologies: false
};

export const BREAKPOINT_COLUMNS = {
  default: 2,
  671: 1
};
