import Page from 'components/layout/Page';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import CVBookmarks from '../shared/CVBookmarks';
import PublicProfileAbout from './about-me/PublicProfileAbout';
import PublicProfileTimeline from './my-story/PublicProfileTimeline';
import PublicProfileHeader from './PublicProfileHeader';
import PublicProfileNavBar from './PublicProfileNavBar';
import {
  StyledContainer,
  StyledFooter,
  StyledProfile,
  StyledTab,
  StyledTabContent,
  StyledTabNumber,
  StyledTabs
} from './PublicProfileTimelineComponent.styled';

type PublicProfileTimelineComponentProps = {
  publicManfredite: UIPublicManfredite;
};

const PublicProfileTimelineComponent: React.FC<
  PublicProfileTimelineComponentProps
> = ({ publicManfredite }) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  const TABS = [
    {
      id: 'about',
      name: t('tabs.aboutme'),
      content: <PublicProfileAbout publicManfredite={publicManfredite} />,
      show:
        UIPublicProfileService.hasGeneralInfo(publicManfredite) ||
        UIPublicProfileService.hasAnyTech(publicManfredite)
    },
    {
      id: 'story',
      name: t('tabs.mystory'),
      content: <PublicProfileTimeline publicManfredite={publicManfredite} />,
      show: publicManfredite.timeline && publicManfredite.timeline.length > 0
    },
    {
      id: 'bookmarks',
      name: t('tabs.bookmarks'),
      content: <CVBookmarks publicManfredite={publicManfredite} />,
      show: publicManfredite.bookmarks && publicManfredite.bookmarks.length > 0
    }
  ];

  const VISIBLE_TABS = TABS.filter((tab) => tab.show);

  const [activeTab, setActiveTab] = useState(VISIBLE_TABS[0]?.id || '');

  function setTab(tabName: string): void {
    setActiveTab(tabName);
  }

  const [displayTabs, setDisplayTabs] = useState(false);

  function displayMenu(): void {
    if (displayTabs === false) {
      document.querySelector('body')?.classList.add('mobile-scroll-blocked');
    } else {
      document.querySelector('body')?.classList.remove('mobile-scroll-blocked');
    }
    setDisplayTabs(!displayTabs);
  }

  function closeMenu(): void {
    setDisplayTabs(false);
    document.querySelector('body')?.classList.remove('mobile-scroll-blocked');
  }

  const [displayNavBar, setDisplayNavBar] = useState(false);

  const tabsRef = useRef<HTMLDivElement>(null);

  const handleScroll = (): void => {
    if (tabsRef?.current) {
      const tabsPosition = tabsRef.current.getBoundingClientRect().bottom;

      if (tabsPosition < 0) {
        setDisplayNavBar(true);
      } else {
        setDisplayNavBar(false);
        setDisplayTabs(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Page noTopBar noFooter>
      <PublicProfileNavBar
        publicManfredite={publicManfredite}
        visible={displayNavBar}
        displayMenu={displayMenu}
        displayTabs={displayTabs && VISIBLE_TABS.length > 1}
        displayTrigger={VISIBLE_TABS.length > 1}
      >
        {VISIBLE_TABS.map((tab, index) => (
          <StyledTab
            key={`tab_${tab.id}`}
            type="button"
            role="tab"
            aria-selected={activeTab === tab.id}
            aria-controls="about-tabpanel"
            id="about-tab"
            className={`${activeTab === tab.id ? 'is-active' : ''}`}
            onClick={() => {
              setTab(tab.id);
              closeMenu();
              if (tabsRef?.current) {
                tabsRef.current.scrollIntoView();
              }
            }}
          >
            <StyledTabNumber>0{index + 1}</StyledTabNumber>
            <span>{tab.name}</span>
          </StyledTab>
        ))}
      </PublicProfileNavBar>
      <StyledProfile>
        <StyledContainer>
          <PublicProfileHeader publicManfredite={publicManfredite} />

          {VISIBLE_TABS.length > 1 && (
            <StyledTabs
              role="tablist"
              aria-label={t('tabs.title')}
              ref={tabsRef}
            >
              {VISIBLE_TABS.map((tab, index) => (
                <StyledTab
                  key={`tab_${tab.id}`}
                  type="button"
                  role="tab"
                  aria-selected={activeTab === tab.id}
                  aria-controls="about-tabpanel"
                  id="about-tab"
                  className={`${activeTab === tab.id ? 'is-active' : ''}`}
                  onClick={() => setTab(tab.id)}
                >
                  <StyledTabNumber>0{index + 1}</StyledTabNumber>
                  <span>{tab.name}</span>
                </StyledTab>
              ))}
            </StyledTabs>
          )}

          <StyledTabContent>
            {VISIBLE_TABS.find((tab) => activeTab === tab.id)?.content}
          </StyledTabContent>
        </StyledContainer>

        <StyledFooter>
          <span>{t('footer.poweredBy')}</span>
          <Link href={getAppUrl(APP_URLS.home, locale)}>
            <a>
              <img
                src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                alt="Manfred Tech"
              />
            </a>
          </Link>
          <small>
            &copy; {new Date().getFullYear()} <strong>Manfred Tech</strong> S.L.
          </small>
        </StyledFooter>
      </StyledProfile>
    </Page>
  );
};

export default PublicProfileTimelineComponent;
