import { useI18n } from 'hooks/useI18n';
import { isEmpty } from 'lodash';
import React from 'react';
import { StyledSubSection, StyledTitle } from './ClassicCVAbout.styled';
import { StyledSkill } from './ClassicCVSoftSkills.styled';
import { CClassicCVSoftSkillsProps } from './ClassicCVSoftSkills.types';

// Component.
// // --------------------------------------------------------------------------
export const ClassicCVSoftSkills: React.FC<CClassicCVSoftSkillsProps> = ({
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Guard Conditions.
  // ---------------------------------------------------------------------------
  if (!manfredite.softSkills || isEmpty(manfredite.softSkills)) {
    return null;
  }

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledSubSection>
      <StyledTitle>{t('about.skills')}</StyledTitle>
      {manfredite.softSkills.map((s, i) => (
        <StyledSkill key={i}>
          <img src={s.iconUrl} alt={s.iconAlt} aria-hidden />
          <span>{s.name}</span>
        </StyledSkill>
      ))}
    </StyledSubSection>
  );
};
