import styled from 'styled-components';
import { color, fontWeight } from '../../../theme';

export const StyledCard = styled.div`
  margin-bottom: 6.36mm;
  padding-left: 6.36mm;
  page-break-inside: avoid;

  &:not(:last-child) {
    padding-bottom: 6.36mm;
    border-bottom: 0.26mm solid ${color('midnight_200')};
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4.23mm;

  img {
    max-height: 8.47mm;
    display: block;
    padding-right: 2.65mm;
    margin-right: 2.65mm;
    border-right: 0.26mm solid ${color('midnight_200')};
  }
`;

export const StyledCompany = styled.a`
  font-size: 12pt;
  line-height: 1.25;
  font-weight: ${fontWeight('medium')};
`;

export const StyledTitle = styled.h4`
  font-size: 10.5pt;
  line-height: 1.14;
  font-weight: ${fontWeight('medium')};
  margin: 0;
  position: relative;

  &::before {
    content: '';
    width: 4.23mm;
    height: 0;
    border-top: 0.26mm solid ${color('midnight_200')};
    position: absolute;
    left: -6.36mm;
    top: 2.21mm;
  }
`;

export const StyledDate = styled.div`
  margin-top: 1.06mm;
  font-size: 7.5pt;
  line-height: 1.14;
  font-weight: ${fontWeight('extraBold')};
  display: flex;
  flex-wrap: wrap;
  gap: 1.06mm;
`;

export const StyledDateRange = styled.div`
  > :first-child::after {
    content: ' — ';
    color: ${color('text_default')};
  }
`;

export const StyledEllapsedTime = styled.span`
  font-weight: ${fontWeight('medium')};
  white-space: nowrap;
`;

export const StyledDesc = styled.div`
  margin-top: 4.23mm;
  font-size: 7.5pt;
  line-height: 1.6;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-left: 4.23mm;
    margin-top: 4.23mm;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  ul + p,
  ol + p {
    margin-top: 4.23mm;
  }
`;

export const StyledTechsTitle = styled.span`
  display: block;
  font-size: 7.25pt;
  line-height: 1.14;
  text-transform: uppercase;
  font-weight: ${fontWeight('extraBold')};
  margin-top: 4.23mm;
`;

export const StyledTechsList = styled.ul`
  li {
    display: inline-block;
    font-size: 7.5pt;
    line-height: 1.15;
    font-weight: ${fontWeight('medium')};
  }

  li:not(:last-child) {
    &::after {
      content: '/';
      margin-left: 1.06mm;
      margin-right: 1.06mm;
      color: ${color('text_placeholder')};
    }
  }
`;
