import styled from 'styled-components';
import { fontSize, fontWeight, screen, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const StyledLanguages = styled.div`
  line-height: 1.2;
  font-weight: ${fontWeight('black')};
  display: grid;
  flex-direction: column;
  grid-template-columns: min-content min-content;
  gap: ${spacing('lg')};
  align-items: center;

  span {
    font-size: ${fontSize('sm')};
    font-weight: ${fontWeight('medium')};

    &.is-not-defined {
      grid-column: span 2;
    }
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
  }
`;
