import { StyledAvatar } from 'components/shared/Avatar.styled';
import styled, { css } from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  screen,
  shadow,
  spacing,
  zindex
} from 'theme';
import {
  StyledTab,
  StyledTabNumber
} from './PublicProfileTimelineComponent.styled';

export const StyledNavBarInner = styled.div`
  transform: translateY(-100%);
  transition: 0.3s;
  width: 100%;
  height: 4.25rem;
  background-color: ${color('white')};
  display: flex;
  align-items: center;
  box-shadow: ${shadow('md')};
`;

export const StyledNavBar = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: ${zindex('profileNavBar')};
  pointer-events: none;

  ${({ visible }) =>
    visible === true &&
    css`
      pointer-events: unset;
      ${StyledNavBarInner} {
        transform: translateY(0);
      }
    `}
`;

export const StyledContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: ${container('xl')};
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('md')};
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  line-height: 1;

  ${StyledAvatar} {
    flex-shrink: 0;
  }
`;

export const StyledTabs = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${spacing('md')};

  @media (min-width: ${screen('lg')}) {
    gap: ${spacing('lg')};
  }

  @media (max-width: ${screen('mdMax')}) {
    width: 100%;
    padding: ${spacing('lg')};
    background-color: ${color('white')};
    position: absolute;
    right: -100%;
    top: 4.25rem;
    height: calc(100vh - 4.25rem);
    transition: right 0.3s;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;

    &.is-visible {
      right: 0;
    }
  }

  ${StyledTab} {
    @media (max-width: ${screen('mdMax')}) {
      flex-direction: row;

      ${StyledTabNumber} {
        margin-bottom: 0;
        display: flex;
        font-size: ${fontSize('xl2')};
        &::after {
          display: block;
        }
      }
    }

    @media (max-width: ${screen('lgMax')}) {
      padding-left: ${spacing('xs')};
      padding-right: ${spacing('xs')};
    }
  }
`;

export const StyledTrigger = styled.button`
  padding: 0;
  background: unset;
  border: 0;
  margin-left: auto;
  color: ${color('midnight_300')};

  @media (min-width: ${screen('md')}) {
    display: none;
  }

  svg {
    width: ${spacing('lg')} !important;
    height: ${spacing('lg')};
  }
`;
