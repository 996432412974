// Custom hook.

import { useI18n } from 'hooks/useI18n';
import { useMemo } from 'react';
import { UseClassicCVProject } from './ClassicCVProjects.types';

// -----------------------------------------------------------------------------
export const useClassicCVProjects: UseClassicCVProject = ({
  publicManfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------
  const projects = useMemo(
    () =>
      publicManfredite.timeline
        ? publicManfredite.timeline.filter(
            (item) => item.cardType === 'project'
          )
        : [],
    [publicManfredite.timeline]
  );

  // Return.
  // ---------------------------------------------------------------------------
  return {
    projects,
    t
  };
};
