import { Multilingual } from 'interfaces/offer.interface';
import Head from 'next/head';
import { AppUrlPath } from 'utils/app-urls';
import HeadMetadataLangAlternate from './HeadMetadataLangAlternate';

type HeadMetadataProps = {
  title: string;
  desc?: string | null;
  url?: AppUrlPath;
  imgUrl?: string;
  path?: Multilingual;
};

const HeadMetadata: React.FC<HeadMetadataProps> = ({
  title,
  url,
  desc = '',
  imgUrl,
  path
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>

        <meta name="description" content={desc || ''} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc || ''} />
        {imgUrl && <meta property="og:image" content={imgUrl} />}

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="@getmanfred"></meta>
        <meta name="twitter:creator" content="@getmanfred"></meta>
        <meta name="twitter:title" content={title}></meta>
        <meta name="twitter:description" content={desc || ''}></meta>
        {imgUrl && <meta name="twitter:image" content={imgUrl}></meta>}
      </Head>

      <HeadMetadataLangAlternate url={url} path={path} />
    </>
  );
};

export default HeadMetadata;
