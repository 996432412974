import LevelLadder from 'components/shared/levelLadder/LevelLadder';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { Heading2, Heading3 } from 'components/shared/Typography.styled';
import { useI18n } from 'hooks/useI18n';
import {
  UIPublicManfredite,
  UIPublicManfrediteTech
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { LANGUAGES_LEVELS } from 'model';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import {
  StyledFact,
  StyledFactDesc,
  StyledFacts,
  StyledFactTitle,
  StyledLanguage,
  StyledLanguageName,
  StyledLanguages,
  StyledSection,
  StyledSkill,
  StyledSkills,
  StyledSubSection,
  StyledTechs,
  StyledTechsList,
  StyledTechsTitle,
  StyledText
} from './PublicProfileAbout.styled';

type PublicProfileAboutProps = {
  publicManfredite: UIPublicManfredite;
};

type PublicProfileAboutMarkdownContentProps = {
  title: string;
  content: string;
};

const PublicProfileAboutMarkdownContent: React.FC<
  PublicProfileAboutMarkdownContentProps
> = ({ title, content }) => {
  return (
    <StyledSubSection>
      <Heading3>{title}</Heading3>
      <StyledText>
        <MarkdownComponent content={content} />
      </StyledText>
    </StyledSubSection>
  );
};

type PublicProfileAboutTechsProps = {
  title: string;
  techs: UIPublicManfrediteTech[];
};

const PublicProfileAboutTechs: React.FC<PublicProfileAboutTechsProps> = ({
  title,
  techs
}) => {
  return (
    <>
      <StyledTechsTitle>{title}</StyledTechsTitle>
      <StyledTechsList>
        {techs.map((t, i) => (
          <li key={i}>
            <img src={t.iconUrl} alt={t.iconAlt} aria-hidden />
            <span>{t.name}</span>
          </li>
        ))}
      </StyledTechsList>
    </>
  );
};

const PublicProfileAbout: React.FC<PublicProfileAboutProps> = ({
  publicManfredite
}) => {
  const { t } = useI18n('publicProfile');

  const { intro, bestPractices, softSkills, languages, facts, techs } =
    publicManfredite;

  return (
    <div role="tabpanel" id="about-tabpanel" aria-labelledby="about-tab">
      {UIPublicProfileService.hasGeneralInfo(publicManfredite) && (
        <StyledSection>
          <Heading2 editorial>{t('about.generalInfo')}</Heading2>

          {intro && (
            <PublicProfileAboutMarkdownContent
              title={t('about.introduction')}
              content={intro}
            />
          )}

          {bestPractices && (
            <PublicProfileAboutMarkdownContent
              title={t('about.bestPractices')}
              content={bestPractices}
            />
          )}

          {softSkills && softSkills.length > 0 && (
            <StyledSubSection>
              <Heading3>{t('about.skills')}</Heading3>
              <StyledSkills>
                {softSkills.map((s, i) => (
                  <StyledSkill key={i}>
                    <img src={s.iconUrl} alt={s.iconAlt} aria-hidden />
                    <span>{s.name}</span>
                  </StyledSkill>
                ))}
              </StyledSkills>
            </StyledSubSection>
          )}

          {languages && languages.length > 0 && (
            <StyledSubSection>
              <Heading3>{t('about.languages')}</Heading3>
              <StyledLanguages>
                {languages.map((l, i) => (
                  <>
                    <StyledLanguage key={i}>
                      <StyledLanguageName>{l.name}</StyledLanguageName>

                      {l.level !== 'not-defined' && (
                        <LevelLadder
                          level={LANGUAGES_LEVELS.indexOf(l.level)}
                        />
                      )}
                    </StyledLanguage>
                  </>
                ))}
              </StyledLanguages>
            </StyledSubSection>
          )}

          {facts && facts.length > 0 && (
            <StyledSubSection>
              <Heading3>{t('about.facts')}</Heading3>
              <StyledFacts>
                {facts.map((f, i) => (
                  <StyledFact key={i}>
                    <StyledFactTitle>{f.title}</StyledFactTitle>
                    <StyledFactDesc>
                      <MarkdownComponent content={f.desc} />
                    </StyledFactDesc>
                  </StyledFact>
                ))}
              </StyledFacts>
            </StyledSubSection>
          )}
        </StyledSection>
      )}

      {UIPublicProfileService.hasAnyTech(publicManfredite) && (
        <StyledSection>
          <Heading2 editorial>{t('about.techs.title')}</Heading2>
          <StyledTechs>
            {techs?.master && techs?.master.length > 0 && (
              <PublicProfileAboutTechs
                title={t('about.techs.master')}
                techs={techs.master}
              />
            )}
            {techs?.like && techs?.like.length > 0 && (
              <PublicProfileAboutTechs
                title={t('about.techs.like')}
                techs={techs.like}
              />
            )}
            {techs?.dislike && techs?.dislike.length > 0 && (
              <PublicProfileAboutTechs
                title={t('about.techs.dislike')}
                techs={techs.dislike}
              />
            )}
          </StyledTechs>
        </StyledSection>
      )}
    </div>
  );
};

export default PublicProfileAbout;
