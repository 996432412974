import { StyledAvatar } from 'components/shared/Avatar.styled';
import styled from 'styled-components';
import { color, fontSize, fontWeight, spacing } from 'theme';
import { StyledTitle } from './about-me/PdfCVAbout.styled';

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  min-width: 4.34cm;

  ${StyledAvatar} {
    width: 3.175cm;
    height: 3.175cm;
    flex-shrink: 0;
  }
`;

export const StyledName = styled.div`
  border-bottom: 0.26mm solid ${color('midnight_200')};
  padding-bottom: 3.7mm;
  margin-bottom: 3.7mm;

  h1 {
    font-size: 15pt;
    line-height: 1.2;
    font-weight: ${fontWeight('black')};
    margin-top: 3.175mm;
    margin-bottom: 2.112mm;
  }
`;

export const StyledIntro = styled.p`
  font-size: 9pt;
  line-height: 1.333;
  text-transform: uppercase;
  font-weight: ${fontWeight('medium')};
  margin: 0;
`;

export const StyledSectionTitle = styled.p`
  font-size: 7.5pt;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: ${fontWeight('medium')};
  margin-top: 0;
  margin-bottom: 1.05mm;
`;

export const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.65mm;
  border-bottom: 0.26mm solid ${color('midnight_200')};
  padding-bottom: 3.7mm;
  margin-bottom: 3.7mm;
`;

export const StyledDataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.06mm;
  font-size: 7.5pt;
  word-break: break-word;

  svg {
    color: ${color('emerald_600')};
    width: 3.175mm !important;
    height: 3.175mm;
  }

  img {
    width: 3.175mm;
  }
`;

export const StyledLanguages = styled.div`
  padding-top: 6.35mm;
  font-size: 9pt;
  font-weight: ${fontWeight('extraBold')};

  ${StyledTitle} {
    margin-bottom: ${spacing('md')};
  }
`;

export const StyledLanguagesList = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: ${spacing('xs2')};
    margin-bottom: ${spacing('sm')};
    font-size: ${fontSize('sm')};
    font-weight: ${fontWeight('medium')};
  }
`;

export const StyledList = styled.div`
  font-size: 9pt;
  font-weight: ${fontWeight('extraBold')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
