import styled, { keyframes } from 'styled-components';

// Animations.
// -----------------------------------------------------------------------------
const appear = keyframes`
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 25%;
    opacity: 1;
  }
`;

// Styled-Components.
// -----------------------------------------------------------------------------
export const ClassicCVLinkedInButtonRoot = styled.div`
  /* Position */
  right: 10%;
  position: fixed;
  z-index: 100;

  // Others.
  animation: ${appear} 1s ease-in-out forwards;
`;
