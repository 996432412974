import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { UseClassicCVLinkedInButton } from './ClassicCVLinkedInButton.types';

// Custom hook.
// -----------------------------------------------------------------------------
export const useClassicCVLinkedInButton: UseClassicCVLinkedInButton = ({
  onResetLinkedIn
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');
  const router = useRouter();

  // Handlers.
  // ---------------------------------------------------------------------------
  const handleOnClick = useCallback(() => {
    const pathname = window.location.href.split('?')[0];
    const { query } = router;

    delete router.query.linkedin;
    delete router.query.slug;
    delete router.query.view;

    router.replace({ pathname, query }, undefined, { shallow: true });
    onResetLinkedIn();
  }, [onResetLinkedIn, router]);

  // Return.
  // ---------------------------------------------------------------------------
  return {
    onClick: handleOnClick,
    t
  };
};
