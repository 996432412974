import {
  UIPublicManfredite,
  UIPublicManfrediteTimelineItem
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { TimelineType } from './ClassicCVEducation.types';

// Utils.
// -----------------------------------------------------------------------------
export const getTimeline = (
  manfredite: UIPublicManfredite,
  type: TimelineType
): UIPublicManfrediteTimelineItem[] =>
  manfredite.timeline
    ? manfredite.timeline.filter((time) => time.type === type)
    : [];
