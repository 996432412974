import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FromLinkedIn, LinkType } from './ClassicCVHeader.types';

// Constants.
// -----------------------------------------------------------------------------
export const DEFAULT_LINKEDIN_IMPORT: FromLinkedIn = {
  avatar: false,
  name: false,
  position: false,
  whereDoYouLive: false
};

export const LINK_ICON: Record<LinkType, IconDefinition> = {
  github: faGithub,
  linkedIn: faLinkedin,
  stackOverflow: faStackOverflow,
  other: faLink,
  twitter: faTwitter
};
