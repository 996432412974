import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import {
  DEFAULT_FIRST_OF_ROW,
  DEFAULT_FIRTS_OF_COLUMN,
  DEFAULT_IS_SECTION
} from './ClassicCVLinkedIn.constants';
import {
  ClassicCVLinkedInContent,
  ClassicCVLinkedInIcon,
  ClassicCVLinkedInRoot
} from './ClassicCVLinkedIn.styled';
import { ClassicCVLinkedInProps } from './ClassicCVLinkedIn.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVLinkedIn: React.FC<ClassicCVLinkedInProps> = ({
  children,
  firstOfColumn = DEFAULT_FIRTS_OF_COLUMN,
  firstOfRow = DEFAULT_FIRST_OF_ROW,
  isSection = DEFAULT_IS_SECTION
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <ClassicCVLinkedInRoot
      firstOfColumn={firstOfColumn}
      firstOfRow={firstOfRow}
    >
      <ClassicCVLinkedInIcon>
        <FontAwesomeIcon icon={faLinkedin} />
        <span>{t('fromLinkedIn')}</span>
      </ClassicCVLinkedInIcon>
      <ClassicCVLinkedInContent isSection={isSection}>
        {children}
      </ClassicCVLinkedInContent>
    </ClassicCVLinkedInRoot>
  );
};
