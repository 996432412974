import { useI18n } from 'hooks/useI18n';
import { useMemo } from 'react';
import { TIMELINE_CARD_TYPE } from './ClassicCVCareer.constants';
import { UseClassicCVCareer } from './ClassicCVCareer.types';

// Custom Hook
// -----------------------------------------------------------------------------
export const useClassicCVCareer: UseClassicCVCareer = ({
  publicManfredite: { timeline }
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------
  const filteredTimeline = useMemo(() => {
    return timeline?.filter((e) => e.cardType === TIMELINE_CARD_TYPE);
  }, [timeline]);

  // Return.
  // ---------------------------------------------------------------------------
  return {
    timeline: filteredTimeline,
    t
  };
};
