import styled from 'styled-components';
import { color, fontSize, fontWeight, screen, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const StyledFact = styled.div`
  margin-bottom: ${spacing('lg')};
`;

export const StyledFactTitle = styled.p`
  margin: 0;
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle')};
  font-size: ${fontSize('sm')};

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('md')};
  }
`;

export const StyledFactDesc = styled.div`
  margin-top: ${spacing('xs')};
  line-height: 1.2;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
  }

  ul {
    padding-left: 1em;
  }

  li {
    list-style: disc;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;
