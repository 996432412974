import styled from 'styled-components';
import {
  color,
  container,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../theme';

export const StyledSection = styled.div`
  padding-top: ${spacing('xl5')};

  @media (min-width: ${screen('lg')}) {
    padding-top: ${spacing('xl6')};
  }
`;

export const StyledTitle = styled.h2`
  font-family: ${fontFamily('display')};
  font-size: ${fontSize('xl5')};
  line-height: 1;
  font-weight: ${fontWeight('light')};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${spacing('lg')};
  position: relative;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl6')};
  }

  &::before {
    content: '';
    position: absolute;
    top: -${spacing('sm')};
    left: 0;
    width: ${spacing('xl3')};
    height: ${spacing('xs2')};
    background-color: ${color('cyan_700')};

    @media (min-width: ${screen('lg')}) {
      background-color: ${color('mint_900')};
      width: ${spacing('xl4')};
      height: ${spacing('xs')};
      top: -${spacing('md')};
    }
  }
`;

export const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${container('lg')};
  width: 100%;
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};
`;

export const StyledFooter = styled.footer`
  margin-top: ${spacing('xl5')};
  padding-bottom: ${spacing('sm')};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle')};

  img {
    display: block;
    height: ${spacing('xl')};
    margin-top: ${spacing('xs')};
    margin-bottom: ${spacing('md')};
  }

  small {
    font-size: ${fontSize('xs')};
    font-weight: ${fontWeight('medium')};
    color: ${color('text_default')};

    strong {
      font-weight: ${fontWeight('black')};
    }
  }
`;
export const StyledNavItemNumber = styled.span`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('xl2')};
  color: ${color('text_placeholder')};
  transition: color 0.2s;

  &::after {
    content: '/';
    font-weight: ${fontWeight('light')};
    padding-left: ${spacing('xs2')};
    padding-right: ${spacing('xs2')};
    color: ${color('text_disabled')};
  }
`;

export const StyledNavItem = styled.div`
  padding: ${spacing('sm')} ${spacing('md')};
  border-bottom: 1px solid transparent;
  font-family: inherit;
  font-weight: ${fontWeight('medium')};
  font-size: ${fontSize('md')};
  text-transform: uppercase;
  line-height: 1;
  color: ${color('text_default')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  .is-active & {
    border-color: ${color('text_active')};

    ${StyledNavItemNumber} {
      color: ${color('text_active')};
    }
  }
`;

export const ElementsWrapper = styled.div`
  /* Positioning.*/
  position: relative;
`;

export const LinkedInOverlay = styled.div`
  /* Positioning. */
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  /* Color */
  background-color: ${color('info_900')};
  opacity: 0.75;
`;
