import {
  faEnvelope,
  faMapMarkerCheck,
  faPhone
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import {
  StyledData,
  StyledDataItem,
  StyledIntro
} from './ClassicCVHeader.styled';
import { useClassicHeaderData } from './ClassicCVHeaderData.hooks';
import { ClassicCVHeaderDataProps } from './ClassicCVHeaderData.types';
import { ClassicCVHeaderLinks } from './ClassicCVHeaderLinks';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVHeaderData: React.FC<ClassicCVHeaderDataProps> = ({
  email,
  linkedIn,
  manfredite,
  phone
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { combination, hasNameInfo, t } = useClassicHeaderData({
    email,
    linkedIn,
    manfredite,
    phone
  });

  // Helpers.
  // ---------------------------------------------------------------------------
  const renderEmailAndPhone = (): JSX.Element => (
    <StyledData>
      {email && (
        <StyledDataItem>
          <FontAwesomeIcon icon={faEnvelope} />
          <a href={`mailto:${email}`}>{email}</a>
        </StyledDataItem>
      )}
      {phone && (
        <StyledDataItem>
          <FontAwesomeIcon icon={faPhone} />
          <span>{phone}</span>
        </StyledDataItem>
      )}
      {manfredite.whereDoYouLive && (
        <StyledDataItem>
          <FontAwesomeIcon icon={faMapMarkerCheck} />
          <span>{manfredite.whereDoYouLive}</span>
        </StyledDataItem>
      )}
    </StyledData>
  );

  const renderName = (): JSX.Element => (
    <h1>
      {hasNameInfo
        ? `${manfredite.firstName} ${manfredite.lastName}`
        : t('unknownManfredite')}
    </h1>
  );

  const renderPosition = (): '' | JSX.Element | undefined =>
    manfredite.headline && <StyledIntro>{manfredite.headline}</StyledIntro>;

  const renderNamePosition = (): JSX.Element => (
    <>
      <ClassicCVLinkedIn>
        {renderName()}
        {renderPosition()}
      </ClassicCVLinkedIn>
      {renderEmailAndPhone()}
    </>
  );

  const renderNone = (): JSX.Element => (
    <>
      {renderName()}
      {renderPosition()}
      {renderEmailAndPhone()}
    </>
  );

  const renderOnlyName = (): JSX.Element => (
    <>
      <ClassicCVLinkedIn>{renderName()}</ClassicCVLinkedIn>
      {renderPosition()}
      {renderEmailAndPhone()}
    </>
  );

  const renderLocationNamePosition = (): JSX.Element => (
    <ClassicCVLinkedIn>
      {renderName()}
      {renderPosition()}
      <StyledData>
        <StyledDataItem>
          <FontAwesomeIcon icon={faMapMarkerCheck} />
          <span>{manfredite.whereDoYouLive}</span>
        </StyledDataItem>
      </StyledData>
    </ClassicCVLinkedIn>
  );

  const renderLocationName = (): JSX.Element => (
    <>
      <ClassicCVLinkedIn>{renderName()}</ClassicCVLinkedIn>
      {renderPosition()}
      <StyledData>
        {email && (
          <StyledDataItem>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href={`mailto:${email}`}>{email}</a>
          </StyledDataItem>
        )}
        {phone && (
          <StyledDataItem>
            <FontAwesomeIcon icon={faPhone} />
            <span>{phone}</span>
          </StyledDataItem>
        )}
        <ClassicCVLinkedIn firstOfRow>
          <StyledDataItem>
            <FontAwesomeIcon icon={faMapMarkerCheck} />
            <span>{manfredite.whereDoYouLive}</span>
          </StyledDataItem>
        </ClassicCVLinkedIn>
      </StyledData>
    </>
  );

  // Return.
  // ---------------------------------------------------------------------------
  return (
    <div>
      {combination === 'location-name-position' && renderLocationNamePosition()}
      {combination === 'location-name' && renderLocationName()}
      {combination === 'name-position' && renderNamePosition()}
      {combination === 'none' && renderNone()}
      {combination === 'only-name' && renderOnlyName()}

      <ClassicCVHeaderLinks manfredite={manfredite} />
    </div>
  );
};
