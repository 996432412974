import { StyledAvatar } from 'components/shared/Avatar.styled';
import { Heading1 } from 'components/shared/Typography.styled';
import styled from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from 'theme';

export const StyledHeader = styled.header`
  padding-bottom: ${spacing('xl4')};

  ${StyledAvatar} {
    margin-bottom: ${spacing('xl')};
  }

  ${Heading1}::before {
    transform: translateX(0);
    left: 0;
  }
`;

export const StyledIntro = styled.div`
  font-size: ${fontSize('lg')};
  line-height: 1.25;
  font-family: ${fontFamily('serif')};
  margin-top: ${spacing('lg')};
  margin-bottom: ${spacing('lg')};

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${screen('md')}) {
    font-style: italic;
    font-size: ${fontSize('xl2')};
    line-height: 1.14;
  }
`;

export const StyledLocation = styled.p`
  font-size: ${fontSize('lg')};
  line-height: 1.25;
  font-family: ${fontFamily('serif')};
  margin-top: 0;
  margin-bottom: 0;

  strong {
    font-weight: ${fontWeight('bold')};
  }

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl2')};
    line-height: 1.14;
    font-style: italic;
  }
`;

export const StyledSocial = styled.div`
  margin-top: ${spacing('lg')};
  display: flex;
`;

export const StyledData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('xs')};
  margin-top: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${spacing('sm')};
  }

  @media (min-width: ${screen('lg')}) {
    margin-top: ${spacing('lg')};
    gap: ${spacing('lg')};
  }
`;

export const StyledDataItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('xs2')};
  font-size: ${fontSize('xs')};

  @media (min-width: ${screen('md')}) {
    gap: ${spacing('xs')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('md')};
  }

  svg {
    color: ${color('emerald_600')};
    width: ${spacing('md')} !important;
    height: ${spacing('md')};

    @media (min-width: ${screen('lg')}) {
      width: ${spacing('lg')} !important;
      height: ${spacing('lg')};
    }
  }
`;
