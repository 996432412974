import styled, { css } from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../theme';

export const Heading1 = styled.h1<{
  editorial?: boolean;
  alignLeft?: boolean;
}>`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('xl3')};
  line-height: 1.17;
  margin: 0;

  ${({ editorial }) =>
    editorial &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(${spacing('sm')} * -1);
        left: 50%;
        transform: translateX(-50%);
        width: ${spacing('xl3')};
        height: ${spacing('xs2')};
        background-color: ${color('cyan_700')};
      }
    `}

  ${({ alignLeft }) =>
    alignLeft &&
    css`
      margin-top: ${spacing('xl4')};
      user-select: none;

      &::before {
        left: 0;
        transform: translateX(0);
      }
    `}

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl4')};

    ${({ editorial }) =>
      editorial &&
      css`
        &::before {
          width: ${spacing('xl4')};
          height: ${spacing('sm')};
        }
      `}
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl5')};
  }
`;

export const Heading2 = styled.h2<{
  editorial?: boolean;
  alignLeft?: boolean;
}>`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('xl2')};
  line-height: 1.17;
  margin: 0;

  ${({ editorial }) =>
    editorial &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(${spacing('sm')} * -1);
        left: 50%;
        transform: translateX(-50%);
        width: ${spacing('xl3')};
        height: ${spacing('xs2')};
        background-color: ${color('indigo_500')};
      }
    `}
  ${({ alignLeft }) =>
    alignLeft &&
    css`
      margin-top: ${spacing('xl4')};
      user-select: none;

      &::before {
        left: 0;
        transform: translateX(0);
      }
    `}

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl3')};

    ${({ editorial }) =>
      editorial &&
      css`
        &::before {
          height: ${spacing('xs')};
        }
      `}
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl4')};
  }
`;

export const Heading3 = styled.h3<{
  editorial?: boolean;
  alignLeft?: boolean;
}>`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('xl')};
  line-height: 1.12;
  margin: 0;

  ${({ editorial }) =>
    editorial &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: calc(${spacing('sm')} * -1);
        left: 50%;
        transform: translateX(-50%);
        width: ${spacing('xl3')};
        height: ${spacing('xs2')};
        background-color: ${color('mint_900')};
      }
    `}

  ${({ alignLeft }) =>
    alignLeft &&
    css`
      margin-top: ${spacing('xl4')};
      user-select: none;

      &::before {
        left: 0;
        transform: translateX(0);
      }
    `}

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl2')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl3')};
  }
`;

export const Heading4 = styled.h4`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('lg')};
  line-height: 1.14;
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl2')};
  }
`;

export const Heading5 = styled.h5`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('md')};
  line-height: 1.14;
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl')};
  }
`;

export const Heading6 = styled.h6`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
  }
`;
