import React from 'react';
import Masonry from 'react-masonry-css';
import {
  BREAKPOINT_COLUMNS,
  DEFAULT_FROM_LINKEDIN
} from './ClassicCVAbout.constants';
import { useClassicCVAbout } from './ClassicCVAbout.hooks';
import { StyledSection } from './ClassicCVAbout.styled';
import { ClassicCVAboutProps } from './ClassicCVAbout.types';
import { ClassicCVAboutMarkdownContent } from './ClassicCVAboutMarkdownContent';
import { ClassicCVFacts } from './ClassicCVFacts';
import { ClassicCVLangs } from './ClassicCVLangs';
import { ClassicCVSoftSkills } from './ClassicCVSoftSkills';
import { ClassicCVTechs } from './ClassicCVTechs';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVAbout: React.FC<ClassicCVAboutProps> = ({
  fromLinkedIn = DEFAULT_FROM_LINKEDIN,
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { hasGeneralInfo, hasOnlyDesc, t } = useClassicCVAbout({ manfredite });

  // Guard clauses.
  // ---------------------------------------------------------------------------
  if (!hasGeneralInfo) {
    return null;
  }

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <div role="tabpanel" id="about-tabpanel" aria-labelledby="about-tab">
      <StyledSection>
        <Masonry
          breakpointCols={BREAKPOINT_COLUMNS}
          className="masonry-grid"
          columnClassName={`masonry-column ${
            hasOnlyDesc ? 'is-full-width' : ''
          }`}
        >
          <ClassicCVAboutMarkdownContent
            content={manfredite.intro}
            fromLinkedIn={fromLinkedIn.introduction}
            title={t('about.introduction')}
          />
          <ClassicCVTechs
            fromLinkedIn={fromLinkedIn.technologies}
            manfredite={manfredite}
          />
          <ClassicCVAboutMarkdownContent
            title={t('about.bestPractices')}
            content={manfredite.bestPractices}
          />
          <ClassicCVLangs
            fromLinkedIn={fromLinkedIn.languages}
            hasTechnologies={fromLinkedIn.technologies}
            manfredite={manfredite}
          />
          <ClassicCVSoftSkills manfredite={manfredite} />
          <ClassicCVFacts manfredite={manfredite} />
        </Masonry>
      </StyledSection>
    </div>
  );
};
