import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { ReactNode } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear, isSameDateYearAndMonth } from 'utils/date';
import {
  StyledCard,
  StyledCompany,
  StyledDate,
  StyledDateRange,
  StyledDesc,
  StyledEllapsedTime,
  StyledLogo,
  StyledTechsList,
  StyledTechsTitle,
  StyledTitle
} from './PdfCVCard.styled';

type PdfCVCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
};

const PdfCVCard: React.FC<PdfCVCardProps> = ({ profileItem }) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  function renderEllapsedTime(from: string, to: string): ReactNode {
    const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

    if (!ellapsedTime) {
      return null;
    }

    return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
  }

  return (
    <StyledCard>
      <StyledLogo>
        {profileItem.logoUrl && (
          <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
        )}
        {profileItem.link && profileItem.company && (
          <StyledCompany href={profileItem.link}>
            {profileItem.company}
          </StyledCompany>
        )}
        {!profileItem.link && profileItem.company && (
          <StyledCompany as="span">{profileItem.company}</StyledCompany>
        )}
      </StyledLogo>

      <StyledTitle>{profileItem.title}</StyledTitle>

      {(profileItem.dateInitial || profileItem.dateSingle) && (
        <StyledDate>
          {profileItem.dateInitial &&
            profileItem.dateFinal &&
            !isSameDateYearAndMonth(
              profileItem.dateInitial,
              profileItem.dateFinal
            ) && (
              <>
                <StyledDateRange>
                  <span>
                    {formatDateMonthYear(profileItem.dateInitial, locale)}
                  </span>
                  {profileItem.dateFinal && (
                    <span>
                      {formatDateMonthYear(profileItem.dateFinal, locale)}
                    </span>
                  )}
                </StyledDateRange>
                {renderEllapsedTime(
                  profileItem.dateInitial.toString(),
                  profileItem.dateFinal.toString()
                )}
              </>
            )}

          {profileItem.dateInitial &&
            profileItem.dateFinal &&
            isSameDateYearAndMonth(
              profileItem.dateInitial,
              profileItem.dateFinal
            ) && <>{formatDateMonthYear(profileItem.dateInitial, locale)}</>}

          {profileItem.dateInitial && !profileItem.dateFinal && (
            <>
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                <span>{t('timeline.today')}</span>
              </StyledDateRange>
              {renderEllapsedTime(
                profileItem.dateInitial.toString(),
                new Date().toString()
              )}
            </>
          )}
        </StyledDate>
      )}

      {profileItem.desc && (
        <StyledDesc>
          <MarkdownComponent content={profileItem.desc} />
        </StyledDesc>
      )}

      {profileItem.techs && profileItem.techs.length > 0 && (
        <div>
          <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
          <StyledTechsList>
            {profileItem.techs?.map((t, i) => (
              <li key={i}>{t.name}</li>
            ))}
          </StyledTechsList>
        </div>
      )}
    </StyledCard>
  );
};

export default PdfCVCard;
