import styled from 'styled-components';
import { color, fontSize, fontWeight, screen, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const StyledTechsTitle = styled.h4`
  margin-top: ${spacing('xl')};
  margin-bottom: ${spacing('lg')};
  font-size: ${fontSize('sm')};
  line-height: 1;
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  padding-bottom: ${spacing('xs')};
  border-bottom: 1px solid ${color('midnight_300')};
`;

export const StyledTechsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('lg')};
  margin-bottom: ${spacing('xl4')};

  &:last-of-type {
    margin-bottom: 0;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing('xs2')};
    font-size: ${fontSize('xs2')};
    line-height: 1.15;
    font-weight: ${fontWeight('medium')};

    @media (min-width: ${screen('lg')}) {
      font-size: ${fontSize('sm')};
      gap: ${spacing('xs')};
    }

    img {
      width: ${spacing('lg')};
      height: ${spacing('lg')};
      filter: grayscale(100%);

      @media (min-width: ${screen('lg')}) {
        width: ${spacing('xl')};
        height: ${spacing('xl')};
      }
    }
  }
`;
