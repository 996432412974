import styled from 'styled-components';
import { color, screen, spacing } from 'theme';
import { StyledCard, StyledCardIcon } from '../PublicProfileCard.styled';

export const StyledTimeline = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('xl3')};
  padding-top: ${spacing('lg')};
  padding-bottom: ${spacing('lg')};
  max-width: 53rem;

  @media (min-width: ${screen('md')}) {
    gap: 0;
    background-image: linear-gradient(
      to right,
      transparent,
      transparent calc(50% - 1px),
      ${color('sand_200')} 50%,
      transparent 50%
    );

    > div {
      width: 50%;

      &:nth-of-type(even) {
        margin-right: auto;

        ${StyledCard} {
          margin-right: 4.5rem;
        }

        ${StyledCardIcon} {
          right: -4.5rem;
          transform: translateX(50%);
          &::before {
            right: 100%;
          }
        }
      }

      &:nth-of-type(odd) {
        margin-left: auto;

        ${StyledCard} {
          margin-left: 4.5rem;
        }

        ${StyledCardIcon} {
          left: -4.5rem;
          transform: translateX(-50%);
          &::before {
            left: 100%;
          }
        }
      }
    }
  }
`;
