import styled from 'styled-components';
import { color, fontWeight } from '../../../../theme';
import { StyledDate } from '../PdfCVCard.styled';

export const StyledCard = styled.div`
  margin-bottom: 6.36mm;
  page-break-inside: avoid;

  ${StyledDate} {
    position: relative;
    padding-left: 6.36mm;

    &::before {
      content: '';
      width: 4.23mm;
      height: 0.26mm;
      background-color: ${color('midnight_200')};
      position: absolute;
      left: 0;
      top: 1.06mm;
    }
  }
`;

export const StyledTitle = styled.h4`
  font-size: 7.5pt;
  line-height: 1.2;
  font-weight: ${fontWeight('extraBold')};
  margin: 0;
  display: inline-block;
`;
