import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight, spacing } from 'theme';

export const StyledLevelLadderImage = styled.img``;

export const StyledLevelLadder = styled.div`
  --level1: ${color('sand_150')};
  --level2: ${color('sand_150')};
  --level3: ${color('sand_150')};
  --level4: ${color('sand_150')};
  --level-gradient: linear-gradient(
    to right,
    var(--level1),
    var(--level1) ${spacing('md')},
    transparent ${spacing('md')},
    transparent calc(${spacing('md')} + ${spacing('xs2')}),
    var(--level2) calc(${spacing('md')} + ${spacing('xs2')}),
    var(--level2) calc(${spacing('md')} + ${spacing('xs2')} + ${spacing('md')}),
    transparent calc(${spacing('md')} + ${spacing('xs2')} + ${spacing('md')}),
    transparent
      calc(
        ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
          ${spacing('xs2')}
      ),
    var(--level3)
      calc(
        ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
          ${spacing('xs2')}
      )
  );
  width: 3.5rem;
  height: ${spacing('md')};
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
  background-image: var(--level-gradient);

  &.level_1 {
    --level1: ${color('positive_500')};
  }
  &.level_2 {
    --level1: ${color('positive_500')};
    --level2: ${color('positive_500')};
  }
  &.level_3 {
    --level1: ${color('positive_500')};
    --level2: ${color('positive_500')};
    --level3: ${color('positive_500')};
  }
  &.level_4 {
    --level1: ${color('positive_500')};
    --level2: ${color('positive_500')};
    --level3: ${color('positive_500')};
    --level4: ${color('positive_500')};
  }
`;

export const StyledLevel = styled.figure<{
  type: 'language';
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${spacing('xs2')};
  margin: 0;

  ${({ type }) =>
    type === 'language' &&
    css`
       {
        min-width: 7rem;

        ${StyledLevelLadder} {
          --level-gradient: linear-gradient(
            to right,
            var(--level1),
            var(--level1) ${spacing('md')},
            transparent ${spacing('md')},
            transparent calc(${spacing('md')} + ${spacing('xs2')}),
            var(--level2) calc(${spacing('md')} + ${spacing('xs2')}),
            var(--level2)
              calc(${spacing('md')} + ${spacing('xs2')} + ${spacing('md')}),
            transparent
              calc(${spacing('md')} + ${spacing('xs2')} + ${spacing('md')}),
            transparent
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')}
              ),
            var(--level3)
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')}
              ),
            var(--level3)
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')} + ${spacing('md')}
              ),
            transparent
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')} + ${spacing('md')}
              ),
            transparent
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')} + ${spacing('md')} + ${spacing('xs2')}
              ),
            var(--level4)
              calc(
                ${spacing('md')} + ${spacing('xs2')} + ${spacing('md')} +
                  ${spacing('xs2')} + ${spacing('md')} + ${spacing('xs2')}
              )
          );
          width: 4.75rem;
        }
      }
    `}
`;

export const StyledLevelCaption = styled.figcaption`
  font-size: ${fontSize('xs2')};
  font-weight: ${fontWeight('medium')};
  text-transform: uppercase;
  line-height: 1.333;
  color: ${color('text_subtle')};
  text-align: right;
`;
