import styled from 'styled-components';
import {
  color,
  container,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from 'theme';

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledContainer = styled.div`
  flex-grow: 1;
  margin: ${spacing('xl')} auto;
  width: 100%;
  max-width: ${container('lg')};
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    margin: ${spacing('xl4')} auto;
  }
`;

export const StyledTabs = styled.div`
  display: flex;
  gap: ${spacing('md')};
  margin-bottom: ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    gap: ${spacing('xl')};
  }
`;

export const StyledTabNumber = styled.span`
  font-family: ${fontFamily('serif')};
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('lg')};
  color: ${color('text_placeholder')};
  transition: color 0.2s;
  margin-bottom: ${spacing('sm')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl2')};
    margin-bottom: 0;
  }

  &::after {
    content: '/';
    font-weight: ${fontWeight('light')};
    padding-left: ${spacing('xs2')};
    padding-right: ${spacing('xs2')};
    color: ${color('text_disabled')};

    @media (max-width: ${screen('mdMax')}) {
      display: none;
    }
  }
`;

export const StyledTab = styled.button`
  padding: ${spacing('sm')} ${spacing('xs')};
  background: unset;
  border: 0;
  border-bottom: 1px solid transparent;
  font-family: inherit;
  font-weight: ${fontWeight('medium')};
  font-size: ${fontSize('md')};
  text-transform: uppercase;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
    padding: ${spacing('sm')} ${spacing('md')};
  }

  &.is-active {
    border-color: ${color('text_active')};

    ${StyledTabNumber} {
      color: ${color('text_active')};
    }
  }
`;

export const StyledTabContent = styled.div`
  max-width: ${container('lg')};
  margin: auto;
`;

export const StyledFooter = styled.footer`
  padding-bottom: ${spacing('sm')};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle')};

  img {
    display: block;
    height: ${spacing('xl')};
    margin-top: ${spacing('xs')};
    margin-bottom: ${spacing('md')};
  }

  small {
    font-size: ${fontSize('xs')};
    font-weight: ${fontWeight('medium')};
    color: ${color('text_default')};

    strong {
      font-weight: ${fontWeight('black')};
    }
  }
`;
