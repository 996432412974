import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { formatDateMonthYear } from 'utils/date';
import { StyledDate } from '../PdfCVCard.styled';
import { StyledCard, StyledTitle } from './PdfCVHighlightItem.styled';

type PdfCVHighlightItemProps = {
  profileItem: UIPublicManfrediteTimelineItem;
};

const PdfCVHighlightItem = ({ profileItem }: PdfCVHighlightItemProps) => {
  const { locale } = useRouter();

  return (
    <StyledCard>
      {profileItem.link && (
        <StyledTitle as="a" href={profileItem.link}>
          {profileItem.title}
        </StyledTitle>
      )}
      {!profileItem.link && <StyledTitle>{profileItem.title}</StyledTitle>}

      {profileItem.dateSingle && (
        <StyledDate>
          <span>{formatDateMonthYear(profileItem.dateSingle, locale)}</span>
        </StyledDate>
      )}
    </StyledCard>
  );
};

export default PdfCVHighlightItem;
