import { TFunction } from 'hooks/useI18n';
import { ProfileLinks } from 'interfaces/manfredite.interface';
import {
  PublicManfredite,
  PublicManfrediteCareerJobPosition,
  PublicManfrediteCertification,
  PublicManfrediteEducation,
  PublicManfrediteHighlight,
  PublicManfrediteProject,
  PublicTech
} from 'interfaces/public-manfredite.interface';
import {
  UIPublicManfredite,
  UIPublicManfrediteBookmark,
  UIPublicManfrediteLink,
  UIPublicManfrediteTech,
  UIPublicManfrediteTimelineItem
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import {
  DEFAULT_LOCALE,
  getTranslatedText
} from 'services/multilingual.service';
import { CommonDtoLevelToLanguageLevelDict } from 'services/service.language.normalizer';
import { config } from 'utils/config';
import { monthDiff } from 'utils/date';

const UIPublicProfileService = {
  /**
   * Checks if given Manfredite Public info has any tech
   *
   * @param publicManfredite - Public Manfredite info
   * @returns wether the given manfredite has any techs
   */
  hasAnyTech: (publicManfredite: UIPublicManfredite): boolean => {
    return (
      !techsEmpty(publicManfredite.techs?.master) ||
      !techsEmpty(publicManfredite.techs?.like) ||
      !techsEmpty(publicManfredite.techs?.dislike)
    );
  },

  /**
   * Given two dates, format the ellapsed time string
   *
   * @param from - initial date
   * @param to - end date
   * @param t - i18n translation function
   * @returns wether the given manfredite has any of the general info values
   */
  formatEllapsedTime: (from: string, to: string, t: TFunction): string => {
    const diff = monthDiff(new Date(from), new Date(to));

    if (diff > 12) {
      const years = Math.abs(Math.floor(diff / 12));
      const months = diff - years * 12;

      if (months > 0) {
        return `${t('timeline.timeDifference.year', { count: years })} ${t(
          'timeline.timeDifference.and'
        )} ${t('timeline.timeDifference.month', { count: months })}`;
      }

      return t('timeline.timeDifference.year', { count: years });
    }

    if (diff > 0) {
      return t('timeline.timeDifference.month', { count: diff });
    }

    return '';
  },

  /**
   * Check if a manfredite has any of the general info values
   *
   * @param publicManfredite - Public Manfredite info
   * @returns wether the given manfredite has any of the general info values
   */
  hasGeneralInfo: (publicManfredite: UIPublicManfredite): boolean => {
    const { intro, bestPractices, softSkills, languages, facts } =
      publicManfredite;

    return (
      !!intro ||
      !!bestPractices ||
      (!!softSkills && softSkills.length > 0) ||
      (!!languages && languages.length > 0) ||
      (!!facts && facts.length > 0)
    );
  },

  /**
   * Get metadata info about the given UIPublicManfredite
   *
   * @param uiPublicManfredite - The UIPublicManfredite from where the Metadata will be extracted
   * @param t - Translation function
   * @returns an object with the title, the desc and the image URL for the metadata
   */
  getManfrediteProfileMetadataInfo: (
    uiPublicManfredite: UIPublicManfredite,
    t: TFunction
  ): {
    title: string;
    desc: string;
    avatarUrl?: string;
  } => {
    const MAX_DESC_LENGTH = 140;
    const { firstName, lastName, headline, intro, avatarUrl } =
      uiPublicManfredite;

    const title =
      !firstName && !lastName
        ? t('unknownManfredite')
        : `${firstName || ''} ${lastName || ''}`.trim();

    const headlineText = headline ? `${headline} - ` : '';

    let desc = `${headlineText}${intro}`;

    if (desc.length > MAX_DESC_LENGTH) {
      desc = `${desc.substring(0, MAX_DESC_LENGTH - 1)}…`;
    }

    return {
      title: `${title} · CV`,
      desc,
      avatarUrl
    };
  },

  /**
   * Converts a PublicManfredite to a UIPublicManfredite object
   *
   * @param publicManfredite - Public Manfredite info
   * @param locale - language in which we need the Multilingual texts
   * @returns the UIPublicManfredite object
   */
  getManfrediteUIData: (
    publicManfredite: PublicManfredite,
    appLocale?: string
  ): UIPublicManfredite => {
    const {
      profile: { avatar, firstName, lastName, links },
      headline,
      whereDoYouLive,
      introduction,
      goodPractices,
      softSkills,
      languages,
      facts,
      techs,
      techsLike,
      techsDislike
    } = publicManfredite;

    const locale = (
      publicManfredite.preferredLanguage ||
      appLocale ||
      DEFAULT_LOCALE
    ).toUpperCase();

    return {
      avatarUrl: avatar?.url,
      avatarAlt: getTranslatedText(avatar?.alt, locale),
      firstName,
      lastName,
      headline: getTranslatedText(headline, locale),
      whereDoYouLive: getTranslatedText(whereDoYouLive?.displayName, locale),
      links: getLinksFromProfileLinks(links),
      intro: getTranslatedText(introduction, locale),
      bestPractices: getTranslatedText(goodPractices, locale),
      softSkills: softSkills?.map((skill) => ({
        name: getTranslatedText(skill.name, locale),
        iconUrl: skill.icon?.url,
        iconAlt: getTranslatedText(skill.icon?.alt, locale)
      })),
      languages: languages?.map((lang) => ({
        name: getTranslatedText(lang.name, locale),
        level: CommonDtoLevelToLanguageLevelDict[lang.level]
      })),
      facts: facts?.map((fact) => ({
        title: getTranslatedText(fact.title, locale),
        desc: getTranslatedText(fact.description, locale)
      })),
      timeline: getTimeline(publicManfredite, locale),
      bookmarks: getBookmarks(publicManfredite, locale),
      techs:
        techs || techsLike || techsDislike
          ? {
              master: techs?.map((tech) => createUITech(tech, locale)) || [],
              like: techsLike?.map((tech) => createUITech(tech, locale)) || [],
              dislike:
                techsDislike?.map((tech) => createUITech(tech, locale)) || []
            }
          : undefined,
      email: publicManfredite.contact?.email,
      phoneNumber: publicManfredite.contact?.phone,
      slug: publicManfredite.slug
    };
  }
};

function createUITech(
  tech: PublicTech,
  locale?: string
): UIPublicManfrediteTech {
  return {
    name: tech.name,
    iconUrl: tech.icon?.url || config.defaultTechIcon,
    iconAlt: getTranslatedText(tech.icon?.alt, locale) || ''
  };
}

function techsEmpty(techs?: UIPublicManfrediteTech[]): boolean {
  return !techs || techs.length <= 0;
}

function getLinksFromProfileLinks(
  links?: ProfileLinks
): UIPublicManfrediteLink[] {
  const uiLinks: UIPublicManfrediteLink[] = [];

  if (!links) {
    return uiLinks;
  }

  if (links.github) {
    uiLinks.push({
      type: 'github',
      url: links.github
    });
  }

  if (links.twitter) {
    uiLinks.push({
      type: 'twitter',
      url: links.twitter
    });
  }

  if (links.linkedin) {
    uiLinks.push({
      type: 'linkedIn',
      url: links.linkedin
    });
  }

  if (links.stackoverflow) {
    uiLinks.push({
      type: 'stackOverflow',
      url: links.stackoverflow
    });
  }

  if (links.others) {
    links.others
      .filter((link) => !!link.url)
      .forEach((o) => {
        uiLinks.push({
          type: 'other',
          url: o.url,
          desc: o.desc
        });
      });
  }

  return uiLinks;
}

function getBookmarks(
  publicManfredite: PublicManfredite,
  locale?: string
): UIPublicManfrediteBookmark[] {
  const { bookmarks } = publicManfredite;

  return (
    bookmarks?.map(
      ({ type, category, title, description, url, author, logo }) => ({
        type: getType(type) || 'OTHER',
        url,
        author,
        title: getTranslatedText(title, locale),
        desc: getTranslatedText(description, locale),
        category: getTranslatedText(category, locale),
        logoURL: logo?.url,
        logoAlt: getTranslatedText(logo?.alt, locale)
      })
    ) || []
  );
}

function getTimeline(
  publicManfredite: PublicManfredite,
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  const { career, projects, highlights, education, certifications } =
    publicManfredite;

  const publicCareer = getCareerForTimeline(career, locale);
  const publicProjects = getProjectsForTimeline(projects, locale);
  const publicHighlights = getHighlightsForTimeline(highlights, locale);
  const publicEducation = getEducationForTimeline(education, locale);
  const publicCertifications = getCertificationsForTimeline(
    certifications,
    locale
  );

  const timeline = [
    ...publicCareer,
    ...publicProjects,
    ...publicHighlights,
    ...publicEducation,
    ...publicCertifications
  ];
  const timelineCurrent = timeline
    .filter((i) => i.dateInitial && !i.dateFinal)
    .sort(sortTimelineItemByDateInitialDesc);

  const timelineEnded = timeline
    .filter((i) => !!i.dateFinal || !!i.dateSingle)
    .sort(sortTimelineItemByDateSingleDesc);

  return [...timelineCurrent, ...timelineEnded];
}

function getCareerForTimeline(
  career?: PublicManfrediteCareerJobPosition[],
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  return (
    career?.map(
      (jobPosition) =>
        ({
          cardType: 'career',
          title: getTranslatedText(jobPosition.position, locale),
          company: jobPosition.company,
          logoUrl: jobPosition.logo?.url,
          logoAlt: getTranslatedText(jobPosition.logo?.alt, locale),
          dateTotal: '',
          dateInitial: jobPosition.from,
          dateFinal: jobPosition.to,
          dateSingle: jobPosition.to,
          desc: getTranslatedText(jobPosition.description, locale),
          link: jobPosition.url,
          techs:
            jobPosition.techs?.map((tech) => createUITech(tech, locale)) || []
        } as UIPublicManfrediteTimelineItem)
    ) || []
  );
}

const VALID_TYPES = [
  'OPEN_SOURCE',
  'SIDE_PROJECT',
  'LAUNCH',
  'MILESTONE',
  'POST',
  'TALK',
  'VIDEO',
  'DEGREE',
  'COURSE',
  'SELF_TRAINING',
  'READING',
  'WEBSITE',
  'PODCAST',
  'VIDEO',
  'OTHER',
  'AWARD'
];

function getType(type: string): string | undefined {
  return VALID_TYPES.find((t) => t === type) || undefined;
}

function getProjectsForTimeline(
  projects?: PublicManfrediteProject[],
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  return (
    projects?.map(
      (project) =>
        ({
          cardType: 'project',
          type: getType(project.type),
          title: getTranslatedText(project.title, locale),
          logoUrl: project.logo?.url,
          logoAlt: getTranslatedText(project.logo?.alt, locale),
          dateTotal: '',
          dateInitial: project.from,
          dateFinal: project.to,
          dateSingle: project.to,
          desc: getTranslatedText(project.description, locale),
          link: project.url,
          techs: project.techs?.map((tech) => createUITech(tech, locale)) || []
        } as UIPublicManfrediteTimelineItem)
    ) || []
  );
}

function getHighlightsForTimeline(
  highlights?: PublicManfrediteHighlight[],
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  return (
    highlights?.map(
      (highlight) =>
        ({
          cardType: 'highlight',
          type: getType(highlight.type),
          category: getTranslatedText(highlight.category, locale),
          title: getTranslatedText(highlight.title, locale),
          logoUrl: highlight.logo?.url,
          logoAlt: getTranslatedText(highlight.logo?.alt, locale),
          dateTotal: '',
          dateSingle: highlight.date,
          desc: getTranslatedText(highlight.description, locale),
          link: highlight.url,
          techs:
            highlight.techs?.map((tech) => createUITech(tech, locale)) || []
        } as UIPublicManfrediteTimelineItem)
    ) || []
  );
}

function getEducationForTimeline(
  education?: PublicManfrediteEducation[],
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  return (
    education?.map(
      (educationItem) =>
        ({
          cardType: 'education',
          type: getType(educationItem.type),
          title: getTranslatedText(educationItem.title, locale),
          company: educationItem.institution,
          logoUrl: educationItem.logo?.url,
          logoAlt: getTranslatedText(educationItem.logo?.alt, locale),
          dateTotal: '',
          dateInitial: educationItem.from,
          dateFinal: educationItem.to,
          dateSingle: educationItem.to,
          desc: getTranslatedText(educationItem.description, locale),
          link: educationItem.url,
          techs:
            educationItem.techs?.map((tech) => createUITech(tech, locale)) || []
        } as UIPublicManfrediteTimelineItem)
    ) || []
  );
}

function getCertificationsForTimeline(
  certifications?: PublicManfrediteCertification[],
  locale?: string
): UIPublicManfrediteTimelineItem[] {
  return (
    certifications?.map(
      (cert) =>
        ({
          cardType: 'certification',
          title: getTranslatedText(cert.title, locale),
          company: cert.issuingOrganization,
          logoUrl: cert.logo?.url,
          logoAlt: getTranslatedText(cert.logo?.alt, locale),
          dateTotal: '',
          dateInitial: cert.from,
          dateFinal: cert.to,
          dateSingle: cert.to,
          desc: getTranslatedText(cert.description, locale),
          link: cert.url,
          techs: cert.techs?.map((tech) => createUITech(tech, locale)) || []
        } as UIPublicManfrediteTimelineItem)
    ) || []
  );
}

function sortTimelineItemByDateSingleDesc(
  a: UIPublicManfrediteTimelineItem,
  b: UIPublicManfrediteTimelineItem
): number {
  if (a.dateSingle && b.dateSingle) {
    const aDate = new Date(a.dateSingle);
    const bDate = new Date(b.dateSingle);

    if (aDate.getTime() > bDate.getTime()) {
      return -1;
    }

    if (aDate.getTime() < bDate.getTime()) {
      return 1;
    }
  }

  return 0;
}

function sortTimelineItemByDateInitialDesc(
  a: UIPublicManfrediteTimelineItem,
  b: UIPublicManfrediteTimelineItem
): number {
  if (a.dateInitial && b.dateInitial) {
    const aDate = new Date(a.dateInitial);
    const bDate = new Date(b.dateInitial);

    if (aDate.getTime() > bDate.getTime()) {
      return -1;
    }

    if (aDate.getTime() < bDate.getTime()) {
      return 1;
    }
  }

  return 0;
}

export default UIPublicProfileService;
