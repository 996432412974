import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear, isSameDateYearAndMonth } from 'utils/date';
import { StyledShowMore } from './about-me/ClassicCVAbout.styled';
import {
  StyledCard,
  StyledContent,
  StyledDate,
  StyledDateRange,
  StyledDesc,
  StyledEllapsedTime,
  StyledExtraContent,
  StyledLink,
  StyledLogo,
  StyledLogoEduTitle,
  StyledLogoTitle,
  StyledPreTitle,
  StyledTechsItem,
  StyledTechsList,
  StyledTechsLogo,
  StyledTechsTitle,
  StyledTitle
} from './ClasicCVCard.styled';

type ClasicCVCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
  index: number;
};

const ClasicCVCard = ({ profileItem, index }: ClasicCVCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();
  const content = useRef<HTMLDivElement>(null);
  const currentHeight = content.current?.scrollHeight;

  const [setActive, setActiveState] = useState('');

  function toggleContent(): void {
    setActiveState(setActive === '' ? 'is-active' : '');
  }

  const contentHeight = {
    '--content-height': `${currentHeight}px`
  } as React.CSSProperties;

  useEffect(() => {
    if (index === 0) {
      setActiveState('is-active');
    }
  }, [index]);

  function renderEllapsedTime(from: string, to: string): ReactNode {
    const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

    if (!ellapsedTime) {
      return null;
    }

    return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
  }

  const trimmedLink = profileItem.link?.replace(/^https?:\/\//, '');

  return (
    <StyledCard>
      <StyledLogo>
        {profileItem.logoUrl && (
          <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
        )}
        {!profileItem.logoUrl && profileItem.company && (
          <>
            {profileItem.cardType != 'education' && (
              <StyledLogoTitle>{profileItem.company}</StyledLogoTitle>
            )}

            {profileItem.cardType === 'education' && profileItem.link && (
              <StyledLogoEduTitle href={profileItem.link}>
                {profileItem.company}
              </StyledLogoEduTitle>
            )}

            {profileItem.cardType === 'education' && !profileItem.link && (
              <StyledLogoEduTitle as="span">
                {profileItem.company}
              </StyledLogoEduTitle>
            )}
          </>
        )}
        {profileItem.link && (
          <StyledLink>
            <a target="_blank" href={profileItem.link} rel="noreferrer">
              {trimmedLink}
            </a>
          </StyledLink>
        )}
      </StyledLogo>

      <StyledContent>
        {profileItem.cardType === 'career' && !!profileItem.company && (
          <StyledPreTitle>{profileItem.company}</StyledPreTitle>
        )}
        <StyledTitle>{profileItem.title}</StyledTitle>

        {(profileItem.dateInitial || profileItem.dateSingle) && (
          <StyledDate>
            <div>
              {profileItem.dateInitial &&
                profileItem.dateFinal &&
                !isSameDateYearAndMonth(
                  profileItem.dateInitial,
                  profileItem.dateFinal
                ) && (
                  <>
                    <StyledDateRange>
                      <span>
                        {formatDateMonthYear(profileItem.dateInitial, locale)}
                      </span>
                      {profileItem.dateFinal && (
                        <span>
                          {formatDateMonthYear(profileItem.dateFinal, locale)}
                        </span>
                      )}
                    </StyledDateRange>
                    {renderEllapsedTime(
                      profileItem.dateInitial.toString(),
                      profileItem.dateFinal.toString()
                    )}
                  </>
                )}

              {profileItem.dateInitial &&
                profileItem.dateFinal &&
                isSameDateYearAndMonth(
                  profileItem.dateInitial,
                  profileItem.dateFinal
                ) && (
                  <>{formatDateMonthYear(profileItem.dateInitial, locale)}</>
                )}

              {profileItem.dateInitial && !profileItem.dateFinal && (
                <>
                  <StyledDateRange>
                    <span>
                      {formatDateMonthYear(profileItem.dateInitial, locale)}
                    </span>
                    <span>{t('timeline.today')}</span>
                  </StyledDateRange>
                  {renderEllapsedTime(
                    profileItem.dateInitial.toString(),
                    new Date().toString()
                  )}
                </>
              )}
            </div>
          </StyledDate>
        )}

        <StyledExtraContent
          ref={content}
          id={'content_' + profileItem.cardType + index}
          className={`${setActive}`}
          style={contentHeight}
          role="region"
          aria-labelledby={'button_' + profileItem.cardType + index}
        >
          {profileItem.desc && (
            <StyledDesc>
              <MarkdownComponent content={profileItem.desc} />
            </StyledDesc>
          )}

          {profileItem.techs && profileItem.techs.length > 0 && (
            <div>
              <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
              <StyledTechsList>
                {profileItem.techs?.map((t, i) => (
                  <StyledTechsItem key={i}>
                    <StyledTechsLogo>
                      <img src={t.iconUrl} alt={t.iconAlt} />
                    </StyledTechsLogo>
                    <span>{t.name}</span>
                  </StyledTechsItem>
                ))}
              </StyledTechsList>
            </div>
          )}
        </StyledExtraContent>
        {(profileItem.desc ||
          (profileItem.techs && profileItem.techs.length > 0)) && (
          <StyledShowMore>
            <button
              type="button"
              id={'button_' + profileItem.cardType + index}
              className={`${setActive}`}
              onClick={toggleContent}
              aria-expanded={setActive === 'is-active'}
              aria-controls={'content_' + profileItem.cardType + index}
            >
              {setActive != 'is-active' && (
                <FontAwesomeIcon icon={faPlusCircle} />
              )}
              {setActive === 'is-active' && (
                <FontAwesomeIcon icon={faMinusCircle} />
              )}
              <span>
                {setActive != 'is-active' && <>{t('readMore')}</>}
                {setActive === 'is-active' && <>{t('readLess')}</>}
              </span>
            </button>
          </StyledShowMore>
        )}
      </StyledContent>
    </StyledCard>
  );
};

export default ClasicCVCard;
