// Constants.
// -----------------------------------------------------------------------------

import { FromLinkedIn } from './ClassicCVEducation.types';

export const DEFAULT_FROM_LINKENDIN: FromLinkedIn = {
  educations: false
};

export const CLASSIC_EDUCATION_TIMELINE_COURSE = 'COURSE';
export const CLASSIC_EDUCATION_TIMELINE_DEGREE = 'DEGREE';
export const CLASSIC_EDUCATION_TIMELINE_TRAINING = 'TRAINING';
