import styled from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  screen,
  spacing
} from 'theme';

export const StyledSection = styled.div`
  margin-top: ${spacing('xl3')};

  @media (min-width: ${screen('md')}) {
    margin-top: ${spacing('xl5')};
  }

  @media (min-width: ${screen('lg')}) {
    margin-top: ${spacing('xl6')};
  }

  .masonry-grid {
    display: flex;
    margin-left: -${spacing('xl5')}; /* gutter size offset */
    width: auto;
  }
  .masonry-column {
    padding-left: ${spacing('xl5')}; /* gutter size */
    background-clip: padding-box;

    &.is-full-width {
      width: 100% !important;
    }

    &:empty {
      display: none;
    }
  }
`;

export const StyledSubSection = styled.div`
  margin-bottom: ${spacing('xl4')};
`;

export const StyledTitle = styled.h3`
  font-family: ${fontFamily('display')};
  font-size: ${fontSize('xl4')};
  line-height: 1;
  font-weight: ${fontWeight('light')};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${spacing('lg')};
  position: relative;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl5')};
  }

  &::before {
    content: '';
    position: absolute;
    top: -${spacing('sm')};
    left: 0;
    width: ${spacing('xl')};
    height: ${spacing('xs2')};
    background-color: ${color('indigo_500')};

    @media (min-width: ${screen('lg')}) {
      width: ${spacing('xl3')};
      height: ${spacing('xs2')};
      background-color: ${color('cyan_700')};
    }
  }
`;

export const StyledText = styled.div`
  line-height: 1.5;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
    line-height: 1.6;
  }

  &:not(.display-full-text) {
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1em;
  }

  li {
    list-style: disc;
    &:not(:first-child) {
      margin-top: ${spacing('lg')};
    }
  }
`;

export const StyledShowMore = styled.div`
  margin-top: ${spacing('lg')};
  padding: 0;
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  position: relative;
  width: 100%;

  button {
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: ${spacing('xs')};
    cursor: pointer;
  }

  svg {
    flex-shrink: 0;
    width: 1.25rem !important;
    height: 1.25rem;
    color: ${color('text_default')};
  }

  span {
    flex-shrink: 0;
    color: ${color('link')};
    font-weight: ${fontWeight('black')};
    text-transform: uppercase;
    font-size: ${fontSize('sm')};
    line-height: 1;

    @media (min-width: ${screen('lg')}) {
      font-size: ${fontSize('md')};
    }
  }

  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${color('midnight_200')};
  }
`;
