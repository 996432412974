import styled from 'styled-components';
import { color, fontSize, screen, spacing } from 'theme';
import { StyledTitle } from '../PublicProfileClassic.styled';

export const StyledSubSection = styled.div`
  margin-top: ${spacing('xl')};
  margin-bottom: ${spacing('xl5')};

  ${StyledTitle} {
    font-size: ${fontSize('xl4')};

    @media (min-width: ${screen('lg')}) {
      font-size: ${fontSize('xl5')};
    }

    &::before {
      top: -${spacing('sm')};
      width: ${spacing('xl')};
      height: ${spacing('xs2')};
      background-color: ${color('indigo_500')};

      @media (min-width: ${screen('lg')}) {
        width: ${spacing('xl3')};
        background-color: ${color('cyan_700')};
      }
    }
  }
`;
