import styled from 'styled-components';
import { color, fontFamily, fontWeight } from 'theme';

export const StyledSection = styled.div`
  margin-top: 1.27cm;
`;

export const StyledTitle = styled.h2`
  font-family: ${fontFamily('display')};
  font-size: 21pt;
  line-height: 1;
  font-weight: ${fontWeight('light')};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 6.35mm;
  position: relative;
  page-break-after: avoid;

  strong {
    font-weight: ${fontWeight('semibold')};
  }

  &::before {
    content: '';
    position: absolute;
    top: -2.12mm;
    left: 0;
    width: 1.27cm;
    height: 0;
    border-top: 1.06mm solid ${color('midnight_300')};
  }
`;
