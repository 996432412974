import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { Fragment, useCallback, useState } from 'react';
import {
  StyledFilterList,
  StyledFilters,
  StyledFiltersContainer,
  StyledFiltersHeader,
  StyledMobileFilters
} from './CVFilter.styled';

type CVFilterProps = {
  filters: Array<string | undefined>;
  filterTranslationPrefix: string;
  onFilterChange?: (activeFilter: string) => void;
};

const CVFilter: React.FC<CVFilterProps> = ({
  filters,
  onFilterChange = () => {
    // NOOP
  },
  filterTranslationPrefix
}) => {
  const { t } = useI18n('publicProfile');

  const [activeFilter, setActiveFilter] = useState('');

  const [showMobileFilters, setshowMobileFilters] = useState(false);

  function switchFilter(e: string): void {
    setActiveFilter(e);
    onFilterChange(e);
  }

  function displayMobileFilters(): void {
    if (showMobileFilters === false) {
      document.querySelector('body')?.classList.add('mobile-scroll-blocked');
    } else {
      document.querySelector('body')?.classList.remove('mobile-scroll-blocked');
    }
    setshowMobileFilters(!showMobileFilters);
  }

  const renderFilters = useCallback(() => {
    return (
      <>
        {filters
          .filter((f) => typeof f !== 'undefined')
          .map((filter) => (
            <Fragment key={filter}>
              <input
                type="radio"
                id={`${filter}_cvFilter`}
                name="cvFilters"
                defaultChecked={activeFilter === filter}
              />
              <label
                htmlFor={`${filter}_cvFilter`}
                onClick={(): void => switchFilter(filter || '')}
              >
                {t(`${filterTranslationPrefix}.${filter}`)}
              </label>
            </Fragment>
          ))}
      </>
    );
  }, [t, activeFilter, JSON.stringify(filters)]);

  if (filters && filters.length <= 0) {
    return null;
  }

  return (
    <div>
      <StyledMobileFilters
        type="button"
        onClick={displayMobileFilters}
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faBarsFilter} />
        <span>{t('classic.filter.title')}</span>
      </StyledMobileFilters>
      <StyledFilters className={`${showMobileFilters ? 'is-open' : ''}`}>
        <StyledFiltersHeader aria-hidden="true">
          {t('classic.filter.filters')}
          <button type="button" onClick={displayMobileFilters}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </StyledFiltersHeader>
        <StyledFiltersContainer>
          <strong>{t('classic.filter.viewing')}</strong>
          <StyledFilterList>
            <input
              type="radio"
              id="all"
              name="cvFilters"
              defaultChecked={activeFilter === ''}
            />
            <label htmlFor="all" onClick={(): void => switchFilter('')}>
              {t('classic.filter.all')}
            </label>

            {renderFilters()}
          </StyledFilterList>
        </StyledFiltersContainer>
      </StyledFilters>
    </div>
  );
};

export default CVFilter;
