export function formatDateMonthYear(
  date?: string | Date,
  locale?: string
): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(locale || 'en', {
    year: 'numeric',
    month: 'short'
  }).format(typeof date === 'string' ? new Date(date) : date);
}

export function formatDate(date?: string | Date, locale?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(locale || 'en').format(
    typeof date === 'string' ? new Date(date) : date
  );
}

export function formatDateTime(date?: string | Date, locale?: string): string {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat(locale || 'en', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(typeof date === 'string' ? new Date(date) : date);
}

export function monthDiff(d1: Date, d2: Date): number {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months + 1;
}

/**
 * Sort the given values by the next criteria:
 *
 * - First: all the elements without dateTo, sorted by dateFrom descending
 * - Second: all the elements with dateTo, sorted by dateTo descending
 *
 * @param values array of values to be sorted
 * @returns a new array with the values sorted
 */
export function sortByDateToAndFrom<
  T extends { dateFrom: Date; dateTo?: Date }
>(values: T[]): T[] {
  const current = values
    .filter((i) => !i.dateTo)
    .sort((a, b) => sortDateDesc(a.dateFrom, b.dateFrom));

  const ended = values
    .filter((i) => !!i.dateTo)
    .sort((a, b) =>
      a.dateTo && b.dateTo ? sortDateDesc(a.dateTo, b.dateTo) : 0
    );

  return [...current, ...ended];
}

export function sortDateDesc(a: Date | string, b: Date | string): number {
  const aDate = new Date(a);
  const bDate = new Date(b);

  if (aDate.getTime() > bDate.getTime()) {
    return -1;
  }

  if (aDate.getTime() < bDate.getTime()) {
    return 1;
  }

  return 0;
}

export function isSameDateYearAndMonth(
  a?: Date | string,
  b?: Date | string
): boolean {
  if (!a || !b) {
    throw new Error('You must pass a date');
  }

  const aDate = new Date(a);
  const bDate = new Date(b);

  return (
    aDate.getFullYear() === bDate.getFullYear() &&
    aDate.getMonth() === bDate.getMonth()
  );
}
