import styled from 'styled-components';
import { fontSize, fontWeight, screen, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const StyledSkill = styled.div`
  margin-bottom: ${spacing('lg')};
  display: flex;
  align-items: center;
  gap: ${spacing('md')};
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('extraBold')};
  line-height: 1.16;

  img {
    width: ${spacing('xl3')};
    height: ${spacing('xl3')};
    filter: grayscale(100%);

    @media (min-width: ${screen('lg')}) {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
`;
