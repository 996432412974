import { StyledFlag } from 'components/shared/BadgeFlag.styled';
import styled from 'styled-components';
import { color, fontSize, fontWeight, radius, screen, spacing } from 'theme';

export const StyledCard = styled.div`
  background-color: ${color('white')};
  margin-bottom: ${spacing('xl4')};
  border-radius: ${radius('lg')};
  border: 1px solid ${color('midnight_200')};
  padding: ${spacing('lg')};
  background-color: ${color('white')};
`;

export const StyledTitle = styled.h4`
  font-size: ${fontSize('xl')};
  line-height: 1.125;
  font-weight: ${fontWeight('black')};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('xl2')};
  }
`;

export const StyledAuthor = styled.div`
  margin-top: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle')};

  strong {
    font-weight: ${fontWeight('black')};
    color: ${color('text_default')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('md')};
  }
`;

export const StyledBookmarkImage = styled.div`
  margin-top: -${spacing('xl')};
  margin-left: -${spacing('lg')};
  margin-right: -${spacing('lg')};
  margin-bottom: ${spacing('md')};
  border-top-right-radius: ${radius('lg')};
  border-top-left-radius: ${radius('lg')};
  overflow: hidden;

  @media (min-width: ${screen('md')}) {
    margin-top: -${spacing('lg')};
    margin-left: -${spacing('lg')};
    margin-right: -${spacing('lg')};
    margin-bottom: ${spacing('md')};
  }

  img {
    width: 100%;
    max-width: unset !important;
    margin-top: 0;
  }
`;

export const StyledFlagContainer = styled.div`
  position: relative;
  ${StyledFlag} {
    position: absolute;
    top: -${spacing('lg')};
    left: -${spacing('lg')};
    transform: translateY(-50%);

    @media (min-width: ${screen('lg')}) {
      left: -${spacing('lg')};
    }
  }
`;

export const StyledLink = styled.div`
  border-top: 1px solid ${color('midnight_200')};
  margin-top: ${spacing('sm')};
  padding-top: ${spacing('sm')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;
  display: flex;
  gap: ${spacing('xs')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
    line-height: 1.25;
  }

  a {
    font-weight: ${fontWeight('extraBold')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
