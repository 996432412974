import styled from 'styled-components';
import { color, fontWeight } from '../../../theme';

export const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 210mm;
  width: 100%;
  padding: 6.35mm;
`;

export const StyledIntro = styled.div`
  display: flex;
  gap: 8.47mm;
`;

export const StyledFooter = styled.div`
  margin-top: 1.27cm;
  padding: 4.76mm;
  border-radius: 4.23mm;
  border: 0.026mm solid ${color('midnight_200')};
  display: flex;
  gap: 3.18mm;
  font-size: 7.5pt;
  page-break-inside: avoid;

  p {
    margin: 0;
  }
`;

export const StyledQR = styled.div`
  width: 2.33cm;
  height: 2.33cm;
  canvas {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
  }
`;

export const StyledFooterTitle = styled.h6`
  font-weight: ${fontWeight('medium')};
  text-transform: uppercase;
  font-size: 12pt;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 1.06mm;
  strong {
    font-weight: ${fontWeight('extraBold')};
  }
`;

export const StyledFooterLink = styled.div`
  display: flex;
  align-items: center;
  gap: 1.06mm;
  font-weight: ${fontWeight('medium')};
  margin-top: 2.12mm;

  a {
    font-weight: ${fontWeight('extraBold')};
  }

  svg {
    width: 4.23mm !important;
    height: 4.23mm;
    color: ${color('emerald_600')};
  }
`;
