import { TFunction } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { StyledEllapsedTime } from '../ClasicCVCard.styled';
import { DEFAULT_TYPE, ICONS } from './ClassicCVProjectCard.constants';
import {
  IconDefinition,
  ProjectType,
  RenderEllapsedTime
} from './ClassicCVProjectCard.types';

// Utils.
// -----------------------------------------------------------------------------
export const getItemType = (
  item: UIPublicManfrediteTimelineItem
): ProjectType =>
  item.cardType === 'project' || item.cardType === 'highlight'
    ? (item.type as ProjectType)
    : DEFAULT_TYPE;

export const getIcon = (item: UIPublicManfrediteTimelineItem): IconDefinition =>
  ICONS[getItemType(item)];

export const getItemTypeText = (
  item: UIPublicManfrediteTimelineItem,
  t: TFunction
): string => {
  if (item.cardType === 'project') {
    if (!item.type) {
      return '';
    }
    return t(`timeline.type.project.${item.type}`);
  }

  if (item.cardType === 'highlight') {
    return (
      (item.type ? t(`timeline.type.highlight.${item.type}`) : '') +
      (item.category ? `: ${item.category}` : '')
    );
  }
  return '';
};

export const renderEllapsedTime: RenderEllapsedTime = ({ from, t, to }) => {
  const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

  if (!ellapsedTime) {
    return null;
  }

  return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
};
