import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import {
  UIPublicManfredite,
  UIPublicManfrediteTech
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import {
  StyledSkill,
  StyledSubSection,
  StyledTechsList,
  StyledTechsTitle,
  StyledText,
  StyledTitle
} from './PdfCVAbout.styled';

type PdfCVAboutProps = {
  publicManfredite: UIPublicManfredite;
};

type PdfCVAboutMarkdownContentProps = {
  title: string;
  content: string;
};

const PdfCVAboutMarkdownContent: React.FC<PdfCVAboutMarkdownContentProps> = ({
  title,
  content
}) => {
  return (
    <StyledSubSection>
      <StyledTitle>{title}</StyledTitle>
      <StyledText>
        <MarkdownComponent content={content} />
      </StyledText>
    </StyledSubSection>
  );
};

type PdfCVAboutTechsProps = {
  title: string;
  techs: UIPublicManfrediteTech[];
};

const ClassicCVAboutTechs: React.FC<PdfCVAboutTechsProps> = ({
  title,
  techs
}) => {
  return (
    <>
      <StyledTechsTitle>{title}</StyledTechsTitle>
      <StyledTechsList>
        {techs.map((t, i) => (
          <li key={i}>{t.name}</li>
        ))}
      </StyledTechsList>
    </>
  );
};

const PdfCVAbout: React.FC<PdfCVAboutProps> = ({ publicManfredite }) => {
  const { t } = useI18n('publicProfile');

  const { intro, bestPractices, softSkills, techs } = publicManfredite;

  return (
    <div>
      {UIPublicProfileService.hasGeneralInfo(publicManfredite) && (
        <div>
          {intro && (
            <PdfCVAboutMarkdownContent
              title={t('about.introduction')}
              content={intro}
            />
          )}

          {softSkills && softSkills.length > 0 && (
            <StyledSubSection>
              <StyledTitle>{t('about.skills')}</StyledTitle>
              {softSkills.map((s, i) => (
                <StyledSkill key={i}>
                  <img src={s.iconUrl} alt={s.iconAlt} aria-hidden />
                  <span>{s.name}</span>
                </StyledSkill>
              ))}
            </StyledSubSection>
          )}

          {!!bestPractices?.trim() && (
            <PdfCVAboutMarkdownContent
              title={t('about.bestPractices')}
              content={bestPractices}
            />
          )}

          {UIPublicProfileService.hasAnyTech(publicManfredite) && (
            <StyledSubSection>
              <StyledTitle>{t('about.techs.title')}</StyledTitle>
              {techs?.master && techs?.master.length > 0 && (
                <ClassicCVAboutTechs
                  title={t('about.techs.master')}
                  techs={techs.master}
                />
              )}
            </StyledSubSection>
          )}
        </div>
      )}
    </div>
  );
};

export default PdfCVAbout;
