import styled from 'styled-components';
import { spacing } from 'theme';

export const StyledCVBookmarks = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -${spacing('xl3')}; /* gutter size offset */
    width: auto;
  }
  .masonry-column {
    padding-left: ${spacing('xl3')}; /* gutter size */
    background-clip: padding-box;

    &:empty {
      display: none;
    }
  }
`;
