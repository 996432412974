import styled from 'styled-components';
import { color, fontWeight } from '../../../../theme';

export const StyledCard = styled.div`
  margin-bottom: 4.23mm;
  border-radius: 4.23mm;
  border: 0.26mm solid ${color('midnight_200')};
  overflow: hidden;
  display: flex;
  page-break-inside: avoid;
`;

export const StyledLogo = styled.div`
  width: 2.54cm;
  background-color: ${color('midnight_100')};
  padding: 4.23mm;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 1.69cm;
    display: block;
  }
`;

export const StyledContent = styled.div`
  padding: 6.35mm 4.23mm;
  border-left: 0.26mm solid ${color('midnight_200')};
`;

export const StyledTitle = styled.h4`
  font-size: 10.5pt;
  line-height: 1.14;
  font-weight: ${fontWeight('extraBold')};
  margin: 0;
`;

export const StyledCompany = styled.div`
  margin-top: 1.06mm;
  font-weight: ${fontWeight('medium')};
  font-size: 7.5pt;
  line-height: 1.2;
`;
