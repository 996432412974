import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { isEmpty } from 'lodash';
import { StyledSubSection, StyledTitle } from './ClassicCVAbout.styled';
import {
  StyledFact,
  StyledFactDesc,
  StyledFactTitle
} from './ClassicCVFacts.styled';
import { ClassicCVFactsProps } from './ClassicCVFacts.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVFacts: React.FC<ClassicCVFactsProps> = ({
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Guard Conditions.
  // ---------------------------------------------------------------------------
  if (!manfredite.facts || isEmpty(manfredite.facts)) {
    return null;
  }

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledSubSection>
      <StyledTitle>{t('about.facts')}</StyledTitle>
      {manfredite.facts.map((f, i) => (
        <StyledFact key={i}>
          <StyledFactTitle>{f.title}</StyledFactTitle>
          <StyledFactDesc>
            <MarkdownComponent content={f.desc} />
          </StyledFactDesc>
        </StyledFact>
      ))}
    </StyledSubSection>
  );
};
