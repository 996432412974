import { StyledAvatar } from 'components/shared/Avatar.styled';
import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  screen,
  spacing
} from '../../../../theme';

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  padding-top: ${spacing('xl')};

  @media (min-width: ${screen('md')}) {
    padding-top: ${spacing('xl5')};
    flex-direction: row;
    gap: ${spacing('lg')};
  }

  @media (min-width: ${screen('lg')}) {
    align-items: center;
    gap: ${spacing('xl')};
  }

  ${StyledAvatar} {
    width: 8rem;
    height: 8rem;
    flex-shrink: 0;

    @media (min-width: ${screen('lg')}) {
      width: 16rem;
      height: 16rem;
    }
  }

  h1 {
    font-size: ${fontSize('lg')};
    line-height: 1.2;
    font-weight: ${fontWeight('black')};
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: ${screen('md')}) {
      font-size: ${fontSize('xl')};
      margin-bottom: ${spacing('xs')};
    }

    @media (min-width: ${screen('lg')}) {
      font-size: ${fontSize('xl3')};
      line-height: 1.125;
    }
  }
`;

export const StyledIntro = styled.p`
  font-size: ${fontSize('xs')};
  line-height: 1.333;
  text-transform: uppercase;
  font-weight: ${fontWeight('medium')};
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
    line-height: 1.2;
  }
`;

export const StyledData = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing('xs')};
  margin-top: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${spacing('sm')};
  }

  @media (min-width: ${screen('lg')}) {
    margin-top: ${spacing('lg')};
    gap: ${spacing('lg')};
  }
`;

export const StyledDataItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('xs2')};
  font-size: ${fontSize('xs')};
  min-width: 180px;

  @media (min-width: ${screen('md')}) {
    gap: ${spacing('xs')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('md')};
  }

  svg {
    color: ${color('emerald_600')};
    width: ${spacing('md')} !important;
    height: ${spacing('md')};

    @media (min-width: ${screen('lg')}) {
      width: ${spacing('lg')} !important;
      height: ${spacing('lg')};
    }
  }
`;

export const StyledSocial = styled.div`
  margin-top: ${spacing('md')};
  display: flex;
  gap: ${spacing('sm')};

  @media (min-width: ${screen('lg')}) {
    margin-top: ${spacing('lg')};
    gap: ${spacing('md')};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};

    @media (min-width: ${screen('lg')}) {
      width: ${spacing('lg')} !important;
      height: ${spacing('lg')};
    }
  }
`;
