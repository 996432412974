import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipMF from 'components/shared/TooltipMF';
import { LINK_ICON } from './ClassicCVHeader.constants';
import { StyledSocial } from './ClassicCVHeader.styled';
import { ClassicCVHeaderLinksProps } from './ClassicCVHeaderLinks.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVHeaderLinks: React.FC<ClassicCVHeaderLinksProps> = ({
  manfredite: { links }
}) =>
  links ? (
    <StyledSocial>
      {links.map((link, i) => (
        <TooltipMF key={`link_${i}`} content={link.desc} direction="up">
          <a href={link.url} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={LINK_ICON[link.type]} />
          </a>
        </TooltipMF>
      ))}
    </StyledSocial>
  ) : null;
