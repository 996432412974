import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import { StyledSubSection, StyledTitle } from './ClassicCVAbout.styled';
import { DEFAULT_FROM_LINKEDIN } from './ClassicCVTechs.constants';
import { useClassicCVTechs } from './ClassicCVTechs.hooks';
import { ClassicCVTechsProps } from './ClassicCVTechs.types';
import { ClassicCVTechsGroup } from './ClassicCVTechsGroup';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVTechs: React.FC<ClassicCVTechsProps> = ({
  fromLinkedIn = DEFAULT_FROM_LINKEDIN,
  manfredite
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { hasTechs, t } = useClassicCVTechs({ manfredite });

  // Guard clauses.
  // ---------------------------------------------------------------------------
  if (!hasTechs) {
    return null;
  }

  // Functions.
  // ---------------------------------------------------------------------------
  const renderTechs = (): JSX.Element => (
    <>
      <StyledTitle>{t('about.techs.title')}</StyledTitle>
      <ClassicCVTechsGroup
        techs={manfredite.techs?.master}
        title={t('about.techs.master')}
      />
      <ClassicCVTechsGroup
        techs={manfredite.techs?.like}
        title={t('about.techs.like')}
      />
      <ClassicCVTechsGroup
        techs={manfredite.techs?.dislike}
        title={t('about.techs.dislike')}
      />
    </>
  );

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledSubSection>
      {fromLinkedIn ? (
        <ClassicCVLinkedIn firstOfColumn isSection>
          {renderTechs()}
        </ClassicCVLinkedIn>
      ) : (
        renderTechs()
      )}
    </StyledSubSection>
  );
};
