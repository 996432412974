import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { UseClassicCVProjectCard } from './ClassicCVProjectCard.types';
import { getIcon, getItemTypeText } from './ClassicCVProjectCard.utils';

// Custom Hook.
// -----------------------------------------------------------------------------
export const useClassicCVProjectCard: UseClassicCVProjectCard = ({
  profileItem
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  // Variables.
  // ---------------------------------------------------------------------------
  const icon = useMemo(() => getIcon(profileItem), [profileItem]);
  const text = useMemo(() => getItemTypeText(profileItem, t), [profileItem, t]);

  // Return.
  // ---------------------------------------------------------------------------
  return {
    icon,
    locale,
    t,
    text
  };
};
