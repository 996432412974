import Page from 'components/layout/Page';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { Element, Link as LinkScroll } from 'react-scroll';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import CVBookmarks from '../shared/CVBookmarks';
import { ClassicCVAbout } from './about-me';
import ClassicCVCareer from './career/ClassicCVCareer';
import ClassicCVCertifications from './certifications/ClassicCVCertifications';
import { ClassicCVLinkedInButton } from './ClassicCVLinkedInButton';
import ClassicCVNavBar from './ClassicCVNavBar';
import { ClassicCVEducation } from './education';
import ClassicCVHeader from './header/ClassicCVHeader';
import ClassicCVHighlights from './highlights/ClassicCVHighlights';
import { ClassicCVProjects } from './projects/';
import { usePublicProfileClassic } from './PublicProfileClassic.hook';
import {
  ElementsWrapper,
  LinkedInOverlay,
  StyledContainer,
  StyledFooter,
  StyledNavItem,
  StyledNavItemNumber,
  StyledSection,
  StyledTitle
} from './PublicProfileClassic.styled';
import { PublicProfileClassicProps } from './PublicProfileClassic.types';

// Component.
// -----------------------------------------------------------------------------
const PublicProfileClassic: React.FC<PublicProfileClassicProps> = ({
  manfredite,
  setHasLinkedInQuery
}) => {
  const {
    displayNavBar,
    displayTabs,
    hasData,
    hasLinkedIn,
    linkedIn,
    locale,
    onCloseMenu,
    onDisplayMenu,
    onResetLinkedIn,
    sections,
    t
  } = usePublicProfileClassic({ manfredite, setHasLinkedInQuery });

  return (
    <Page noTopBar noFooter pageVariant="classicProfile" sections={sections}>
      <ClassicCVLinkedInButton
        hasLinkedIn={hasLinkedIn}
        onResetLinkedIn={onResetLinkedIn}
      />
      <ClassicCVNavBar
        publicManfredite={manfredite}
        visible={displayNavBar}
        displayMenu={onDisplayMenu}
        displayTabs={displayTabs && !isEmpty(sections)}
        displayTrigger={!isEmpty(sections)}
      >
        {sections.map((item, index) => (
          <LinkScroll
            key={index}
            activeClass="is-active"
            to={item.section}
            href={`#${item.section}`}
            spy={true}
            onClick={onCloseMenu}
          >
            <StyledNavItem>
              <StyledNavItemNumber>0{index + 1}</StyledNavItemNumber>
              <span>{item.tooltip}</span>
            </StyledNavItem>
          </LinkScroll>
        ))}
      </ClassicCVNavBar>

      <ElementsWrapper>
        {hasLinkedIn && <LinkedInOverlay />}
        <StyledContainer>
          <Element name="about_me" id="about">
            <ClassicCVHeader
              email={manfredite.email}
              linkedIn={linkedIn}
              manfredite={manfredite}
              phone={manfredite.phoneNumber}
            />
            <ClassicCVAbout fromLinkedIn={linkedIn} manfredite={manfredite} />
          </Element>

          {hasData.career && (
            <Element name="career" id="career">
              <ClassicCVCareer
                fromLinkedIn={linkedIn}
                publicManfredite={manfredite}
              />
            </Element>
          )}

          {hasData.education && (
            <Element name="education" id="education">
              <ClassicCVEducation
                fromLinkedIn={linkedIn}
                manfredite={manfredite}
              />
            </Element>
          )}

          {hasData.certifications && (
            <Element name="certifications" id="certifications">
              <ClassicCVCertifications publicManfredite={manfredite} />
            </Element>
          )}

          {hasData.projects && (
            <Element name="projects" id="projects">
              <ClassicCVProjects publicManfredite={manfredite} />
            </Element>
          )}

          {hasData.highlights && (
            <Element name="highlights" id="highlights">
              <ClassicCVHighlights publicManfredite={manfredite} />
            </Element>
          )}

          {hasData.bookmarks && (
            <Element name="bookmarks" id="bookmarks">
              <StyledSection>
                <StyledTitle>{t('classic.sections.bookmarks')}</StyledTitle>

                <CVBookmarks publicManfredite={manfredite} />
              </StyledSection>
            </Element>
          )}

          <StyledFooter>
            <span>{t('footer.poweredBy')}</span>
            <Link href={getAppUrl(APP_URLS.home, locale)}>
              <a>
                <img
                  src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                  alt="Manfred Tech"
                />
              </a>
            </Link>
            <small>
              &copy; {new Date().getFullYear()} <strong>Manfred Tech</strong>{' '}
              S.L.
            </small>
          </StyledFooter>
        </StyledContainer>
      </ElementsWrapper>
    </Page>
  );
};

export default PublicProfileClassic;
