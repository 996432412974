import { Multilingual } from 'interfaces/offer.interface';
import Head from 'next/head';
import { getTranslatedText } from 'services/multilingual.service';
import { AppUrlPath } from 'utils/app-urls';
import { config } from 'utils/config';

type HeadMetadataLangAlternateProps = {
  url?: AppUrlPath;
  path?: Multilingual;
};

const HeadMetadataLangAlternate: React.FC<HeadMetadataLangAlternateProps> = ({
  url,
  path
}) => {
  function getPath(path: Multilingual | undefined, locale: string): string {
    if (!path) {
      return '';
    }

    return `/${getTranslatedText(path, locale)}`;
  }
  return (
    <Head>
      {url &&
        Object.keys(url.langs).map((key) => {
          return (
            <link
              key={key}
              rel="alternate"
              hrefLang={key}
              href={`${config.appUrl}/${key}${url.langs[key]}${getPath(
                path,
                key
              )}`}
            />
          );
        })}
    </Head>
  );
};

export default HeadMetadataLangAlternate;
