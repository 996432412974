import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing
} from '../../../../theme';

export const StyledCard = styled.div`
  margin-bottom: ${spacing('lg')};
  border-radius: ${radius('lg')};
  border: 1px solid ${color('midnight_200')};
  overflow: hidden;

  @media (min-width: ${screen('md')}) {
    display: grid;
    grid-template-columns: 11.5rem 1fr;
  }
`;

export const StyledLogo = styled.div`
  background-color: ${color('midnight_100')};
  padding: ${spacing('lg')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid ${color('midnight_200')};

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('lg')} ${spacing('xl')};
    border-bottom: 0;
  }

  &.no-logo {
    display: none;

    @media (min-width: ${screen('md')}) {
      display: flex;
    }
  }

  img {
    max-width: 100%;
    max-height: ${spacing('xl4')};
    display: block;

    @media (min-width: ${screen('md')}) {
      max-height: 7.5rem;
    }
  }
`;

export const StyledContent = styled.div`
  padding: ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('xl')};
    border-left: 1px solid ${color('midnight_200')};
  }
`;

export const StyledTitle = styled.h4`
  font-size: ${fontSize('lg')};
  line-height: 1.16;
  font-weight: ${fontWeight('black')};
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
  }
`;

export const StyledCompany = styled.div`
  margin-top: ${spacing('xs')};
  font-weight: ${fontWeight('medium')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
  }
`;

export const StyledExtraContent = styled.div`
  overflow: hidden;
  margin: 0;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;

  &.is-active {
    max-height: var(--content-height);
  }
`;
