import { UIPublicManfredite } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { Fragment, useState } from 'react';
import Masonry from 'react-masonry-css';
import Fade from 'react-reveal/Fade';
import { StyledCards } from '../classic/highlights/ClassicCVHighlights.styled';
import CVBookmarkCard from './CVBookmarkCard';
import { StyledCVBookmarks } from './CVBookmarks.styled';
import CVFilter from './CVFilter';

type CVBookmarksProps = {
  publicManfredite: UIPublicManfredite;
};

const CVBookmarks: React.FC<CVBookmarksProps> = ({
  publicManfredite: { bookmarks }
}) => {
  const [activeFilter, setActiveFilter] = useState('');
  const shouldShowFilter = bookmarks && bookmarks.length > 8;
  const filters = [...new Set(bookmarks?.map((bookmark) => bookmark.type))];

  const bookmarksColumns = {
    default: 2,
    671: 1
  };

  const handleOnFilterChange = (newFilter: string): void => {
    setActiveFilter(newFilter);
  };

  return (
    <StyledCVBookmarks>
      {shouldShowFilter && (
        <CVFilter
          onFilterChange={handleOnFilterChange}
          filters={filters}
          filterTranslationPrefix="timeline.type.bookmark"
        />
      )}

      <StyledCards>
        <Masonry
          breakpointCols={bookmarksColumns}
          className="masonry-grid"
          columnClassName="masonry-column"
        >
          {bookmarks?.map((item, index) => (
            <Fragment key={index}>
              {(item.type === activeFilter || activeFilter === '') && (
                <Fade>
                  <CVBookmarkCard bookmarkItem={item} />
                </Fade>
              )}
            </Fragment>
          ))}
        </Masonry>
      </StyledCards>
    </StyledCVBookmarks>
  );
};

export default CVBookmarks;
