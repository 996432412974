import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import {
  faBadgeCheck,
  faFilmAlt,
  faKeynote,
  faMedal,
  faNewspaper
} from '@fortawesome/pro-solid-svg-icons';
import BadgeFlag from 'components/shared/BadgeFlag';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { formatDateMonthYear } from 'utils/date';
import {
  StyledDate,
  StyledDesc,
  StyledTechsItem,
  StyledTechsList,
  StyledTechsLogo,
  StyledTechsTitle
} from '../ClasicCVCard.styled';
import {
  StyledCard,
  StyledContent,
  StyledHighlightImage,
  StyledInfo,
  StyledLink,
  StyledLogo,
  StyledTitle
} from './ClasicCVHighlightCard.styled';

type ClasicCVHighlightCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
};

const ClasicCVHighlightCard = ({ profileItem }: ClasicCVHighlightCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  const text = getItemTypeText(profileItem);
  const typeIcons: Record<string, IconProp> = {
    POST: faNewspaper,
    TALK: faKeynote,
    LAUNCH: faRocket,
    MILESTONE: faBadgeCheck,
    VIDEO: faFilmAlt,
    AWARD: faMedal
  };

  const typeColors: Record<string, string> = {
    LAUNCH: 'dark2_03',
    MILESTONE: 'set1_02',
    TALK: 'set1_05',
    VIDEO: 'set1_04',
    POST: 'set1_03',
    AWARD: 'set1_07'
  };

  function getItemTypeText(item: UIPublicManfrediteTimelineItem): string {
    if (item.cardType === 'highlight') {
      return (
        (item.type ? t(`timeline.type.highlight.${item.type}`) : '') +
        (item.category ? `: ${item.category}` : '')
      );
    }
    return '';
  }

  return (
    <StyledCard>
      {profileItem.logoUrl && (
        <StyledHighlightImage>
          <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
        </StyledHighlightImage>
      )}

      <StyledInfo>
        <StyledLogo>
          <BadgeFlag
            color={typeColors[profileItem.type || 'TALK']}
            icon={typeIcons[profileItem.type || 'TALK']}
            text={text}
          />
          {profileItem.link && (
            <StyledTitle as="a" href={profileItem.link}>
              {profileItem.title}
            </StyledTitle>
          )}
          {!profileItem.link && <StyledTitle>{profileItem.title}</StyledTitle>}
          {(profileItem.dateInitial || profileItem.dateSingle) && (
            <StyledDate>
              <div>
                {profileItem.dateSingle && !profileItem.dateInitial && (
                  <span>
                    {formatDateMonthYear(profileItem.dateSingle, locale)}
                  </span>
                )}
              </div>
            </StyledDate>
          )}

          {profileItem.cardType === 'project' && profileItem.logoUrl && (
            <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
          )}
        </StyledLogo>
        {(profileItem.desc ||
          profileItem.link ||
          (profileItem.techs && profileItem.techs.length > 0)) && (
          <StyledContent>
            {profileItem.desc && (
              <StyledDesc>
                <StyledTechsTitle>{t('description')}</StyledTechsTitle>
                <MarkdownComponent content={profileItem.desc} />
              </StyledDesc>
            )}

            {profileItem.link && (
              <StyledLink>
                <span>{t('url')}:</span>{' '}
                <a href={profileItem.link}>{profileItem.link}</a>
              </StyledLink>
            )}

            {profileItem.techs && profileItem.techs.length > 0 && (
              <div>
                <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
                <StyledTechsList>
                  {profileItem.techs?.map((t, i) => (
                    <StyledTechsItem key={i}>
                      <StyledTechsLogo>
                        <img src={t.iconUrl} alt={t.iconAlt} />
                      </StyledTechsLogo>
                      <span>{t.name}</span>
                    </StyledTechsItem>
                  ))}
                </StyledTechsList>
              </div>
            )}
          </StyledContent>
        )}
      </StyledInfo>
    </StyledCard>
  );
};

export default ClasicCVHighlightCard;
