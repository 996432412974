import { faRocket } from '@fortawesome/free-solid-svg-icons';
import {
  faBadgeCheck,
  faBookSpells,
  faFileCode,
  faFilmAlt,
  faKeynote,
  faNewspaper
} from '@fortawesome/pro-solid-svg-icons';
import { Icons, ProjectType } from './ClassicCVProjectCard.types';

// Constants.
// -----------------------------------------------------------------------------
export const DEFAULT_TYPE: ProjectType = 'TALK';

export const ICONS: Icons = {
  LAUNCH: {
    color: 'dark2_03',
    icon: faRocket
  },

  MILESTONE: {
    color: 'set1_02',
    icon: faBadgeCheck
  },

  OPEN_SOURCE: {
    color: 'set2_04',
    icon: faFileCode
  },

  POST: {
    color: 'set1_03',
    icon: faNewspaper
  },

  SIDE_PROJECT: {
    color: 'set1_05',
    icon: faBookSpells
  },

  TALK: {
    color: 'set1_05',
    icon: faKeynote
  },

  VIDEO: {
    color: 'set1_04',
    icon: faFilmAlt
  }
};
