import { isEmpty } from 'lodash';
import {
  StyledTechsList,
  StyledTechsTitle
} from './ClassicCVTechsGroup.styled';
import { ClassicCVTechsGroupProps } from './ClassicCVTechsGroup.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVTechsGroup: React.FC<ClassicCVTechsGroupProps> = ({
  title,
  techs
}) => {
  // Guard clauses.
  // ---------------------------------------------------------------------------
  if (!techs || isEmpty(techs)) {
    return null;
  }

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <>
      <StyledTechsTitle>{title}</StyledTechsTitle>
      <StyledTechsList>
        {techs.map((t, i) => (
          <li key={i}>
            <img src={t.iconUrl} alt={t.iconAlt} aria-hidden />
            <span>{t.name}</span>
          </li>
        ))}
      </StyledTechsList>
    </>
  );
};
