import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faBoxHeart,
  faBrowser,
  faFilmAlt,
  faPodcast
} from '@fortawesome/pro-solid-svg-icons';
import BadgeFlag from 'components/shared/BadgeFlag';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteBookmark } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { StyledDesc } from '../classic/ClasicCVCard.styled';
import {
  StyledAuthor,
  StyledBookmarkImage,
  StyledCard,
  StyledFlagContainer,
  StyledLink,
  StyledTitle
} from './CVBookmarkCard.styled';

type CVBookmarkCardProps = {
  bookmarkItem: UIPublicManfrediteBookmark;
};

const CVBookmarkCard: React.FC<CVBookmarkCardProps> = ({ bookmarkItem }) => {
  const { t } = useI18n('publicProfile');

  const typeIcons: Record<string, IconDefinition> = {
    WEBSITE: faBrowser,
    READING: faBook,
    PODCAST: faPodcast,
    VIDEO: faFilmAlt,
    OTHER: faBoxHeart
  };

  const typeColors: Record<string, string> = {
    VIDEO: 'pastel1_04',
    WEBSITE: 'pastel1_06',
    READING: 'pastel1_02',
    PODCAST: 'pastel1_03',
    OTHER: 'pastel1_05'
  };

  return (
    <StyledCard>
      {bookmarkItem.logoURL && (
        <StyledBookmarkImage>
          <img src={bookmarkItem.logoURL} alt={bookmarkItem.logoAlt || ''} />
        </StyledBookmarkImage>
      )}

      <StyledFlagContainer>
        <BadgeFlag
          color={typeColors[bookmarkItem.type || 'OTHER']}
          icon={typeIcons[bookmarkItem.type || 'OTHER']}
          text={t(`timeline.type.bookmark.${bookmarkItem.type}`)}
        />

        {bookmarkItem.url && (
          <StyledTitle>
            <a href={bookmarkItem.url}>{bookmarkItem.title}</a>
          </StyledTitle>
        )}
        {!bookmarkItem.url && <StyledTitle>{bookmarkItem.title}</StyledTitle>}
      </StyledFlagContainer>
      {bookmarkItem.author && (
        <StyledAuthor>
          {t('author')}: <strong>{bookmarkItem.author}</strong>
        </StyledAuthor>
      )}
      {bookmarkItem.desc && (
        <StyledDesc>
          <MarkdownComponent content={bookmarkItem.desc} />
        </StyledDesc>
      )}
      {bookmarkItem.url && (
        <StyledLink>
          <span>{t('url')}:</span>{' '}
          <a href={bookmarkItem.url} target="_blank" rel="noreferrer">
            {bookmarkItem.url}
          </a>
        </StyledLink>
      )}
    </StyledCard>
  );
};

export default CVBookmarkCard;
