import Avatar from 'components/shared/Avatar';
import { ClassicCVLinkedIn } from '../ClassicCVLinkedIn';
import { ClassicCVHeaderAvatarProps } from './ClassicCVHeaderAvatar.types';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVHeaderAvatar: React.FC<ClassicCVHeaderAvatarProps> = ({
  linkedIn,
  manfredite: { avatarAlt, avatarUrl }
}) => {
  // Guard condition.
  // ---------------------------------------------------------------------------
  if (!avatarUrl) {
    return null;
  }

  // Functions.
  // ---------------------------------------------------------------------------
  const renderAvatar = (): JSX.Element => (
    <Avatar avatarImg={avatarUrl} avatarAlt={avatarAlt || ''} sizes="lg" />
  );

  // Render.
  // ---------------------------------------------------------------------------
  return linkedIn.avatar ? (
    <ClassicCVLinkedIn>{renderAvatar()}</ClassicCVLinkedIn>
  ) : (
    renderAvatar()
  );
};
