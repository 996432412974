import styled from 'styled-components';
import { color, fontWeight } from 'theme';

export const StyledSubSection = styled.div`
  margin-bottom: 8.47mm;
`;

export const StyledTitle = styled.h3`
  font-size: 12pt;
  line-height: 1.25;
  font-weight: ${fontWeight('black')};
  margin-top: 0;
  margin-bottom: 2.12mm;
  page-break-after: avoid;
`;

export const StyledText = styled.div`
  line-height: 1.6;
  font-size: 7.5pt;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1em;
  }

  li {
    list-style: disc;
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
`;

export const StyledSkill = styled.div`
  display: flex;
  align-items: center;
  gap: 2.65mm;
  font-size: 7.5pt;
  font-weight: ${fontWeight('medium')};
  line-height: 1.16;

  &:not(:last-child) {
    margin-bottom: 2.12mm;
  }

  img {
    width: 6.35mm;
    height: 6.35mm;
  }
`;

export const StyledTechsTitle = styled.h4`
  margin-bottom: 2.12mm;
  font-size: 9pt;
  line-height: 1;
  font-weight: ${fontWeight('extraBold')};
  text-transform: uppercase;
  padding-bottom: 0.53mm;
  border-bottom: 0.26mm solid ${color('midnight_300')};
  page-break-after: avoid;
`;

export const StyledTechsList = styled.ul`
  page-break-before: avoid;
  li {
    display: inline-block;
    font-size: 7.5pt;
    line-height: 1.15;
    font-weight: ${fontWeight('medium')};
  }

  li:not(:last-child) {
    &::after {
      content: '/';
      margin-left: 1.06mm;
      margin-right: 1.06mm;
      color: ${color('text_placeholder')};
    }
  }
`;
