import { useI18n } from 'hooks/useI18n';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { UseClassicCVAbout } from './ClassicCVAbout.types';

// Custom hook.
// -----------------------------------------------------------------------------
export const useClassicCVAbout: UseClassicCVAbout = ({ manfredite }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------
  const hasOnlyDesc = useMemo(() => {
    const { bestPractices, softSkills, languages, facts } = manfredite;

    return (
      !UIPublicProfileService.hasAnyTech(manfredite) &&
      !bestPractices &&
      !isEmpty(languages) &&
      !isEmpty(softSkills) &&
      !isEmpty(facts)
    );
  }, [manfredite]);

  const hasGeneralInfo = useMemo(
    () => UIPublicProfileService.hasGeneralInfo(manfredite),
    [manfredite]
  );

  // Return.
  // ---------------------------------------------------------------------------
  return {
    hasGeneralInfo,
    hasOnlyDesc,
    t
  };
};
