import { useI18n } from 'hooks/useI18n';
import { getPublicPath } from 'utils/paths';
import {
  StyledLevel,
  StyledLevelCaption,
  StyledLevelLadder,
  StyledLevelLadderImage
} from './LevelLadder.styled';
import { LevelProps } from './LevelLadder.types';

const LevelLadder: React.FC<LevelProps> = ({
  level,
  type = 'language',
  useImage = false
}) => {
  const { t } = useI18n('common');

  return (
    <StyledLevel type={type}>
      {useImage ? (
        <StyledLevelLadderImage
          src={getPublicPath(`/images/level-ladder/level_${level}.png`)}
        />
      ) : (
        <StyledLevelLadder className={'level_' + level} />
      )}

      {type === 'language' && (
        <StyledLevelCaption>
          {level === 1 && t('language-selector.level.options.basic')}
          {level === 2 && t('language-selector.level.options.intermediate')}
          {level === 3 && t('language-selector.level.options.fluid')}
          {level === 4 && t('language-selector.level.options.native')}
        </StyledLevelCaption>
      )}
    </StyledLevel>
  );
};

export default LevelLadder;
