import { useI18n } from 'hooks/useI18n';
import { UIPublicManfrediteTimelineItem } from 'interfaces/ui/publicprofile/ui-public-manfredite';
import { useRouter } from 'next/dist/client/router';
import { ReactNode } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { formatDateMonthYear } from 'utils/date';
import { getPublicPath } from 'utils/paths';
import {
  StyledDate,
  StyledDateRange,
  StyledEllapsedTime
} from '../PdfCVCard.styled';
import {
  StyledCard,
  StyledCompany,
  StyledContent,
  StyledLogo,
  StyledTitle
} from './PdfCVCertificationsCard.styled';

type PdfCVCertificationsCardProps = {
  profileItem: UIPublicManfrediteTimelineItem;
};

const PdfCVCertificationsCard = ({
  profileItem
}: PdfCVCertificationsCardProps) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  function renderEllapsedTime(from: string, to: string): ReactNode {
    const ellapsedTime = UIPublicProfileService.formatEllapsedTime(from, to, t);

    if (!ellapsedTime) {
      return null;
    }

    return <StyledEllapsedTime>({ellapsedTime})</StyledEllapsedTime>;
  }

  return (
    <StyledCard>
      <StyledLogo>
        {profileItem.logoUrl && (
          <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
        )}
        {!profileItem.logoUrl && (
          <img
            src={getPublicPath('/images/profile/certification.svg')}
            alt=""
          />
        )}
      </StyledLogo>
      <StyledContent>
        {profileItem.link && (
          <StyledTitle as="a" href={profileItem.link}>
            {profileItem.title}
          </StyledTitle>
        )}
        {!profileItem.link && <StyledTitle>{profileItem.title}</StyledTitle>}

        {profileItem.company && (
          <StyledCompany>
            {t('issuedBy', { company: profileItem.company })}
          </StyledCompany>
        )}

        {(profileItem.dateInitial || profileItem.dateSingle) && (
          <StyledDate>
            {profileItem.dateInitial && profileItem.dateFinal && (
              <>
                <StyledDateRange>
                  <span>
                    {formatDateMonthYear(profileItem.dateInitial, locale)}
                  </span>
                  {profileItem.dateFinal && (
                    <span>
                      {formatDateMonthYear(profileItem.dateFinal, locale)}
                    </span>
                  )}
                </StyledDateRange>

                {renderEllapsedTime(
                  profileItem.dateInitial.toString(),
                  profileItem.dateFinal.toString()
                )}
              </>
            )}

            {profileItem.dateInitial && !profileItem.dateFinal && (
              <>
                <StyledDateRange>
                  <span>
                    {formatDateMonthYear(profileItem.dateInitial, locale)}
                  </span>
                  <span>{t('timeline.today')}</span>
                </StyledDateRange>
                {renderEllapsedTime(
                  profileItem.dateInitial.toString(),
                  new Date().toString()
                )}
              </>
            )}
          </StyledDate>
        )}
      </StyledContent>
    </StyledCard>
  );
};

export default PdfCVCertificationsCard;
