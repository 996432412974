import {
  faGithub,
  faLinkedin,
  faStackOverflow,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLink, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/shared/Avatar';
import { ButtonLink } from 'components/shared/ButtonLink';
import TooltipMF from 'components/shared/TooltipMF';
import { Heading1 } from 'components/shared/Typography.styled';
import { useI18n } from 'hooks/useI18n';
import {
  UIPublicManfredite,
  UIPublicManfrediteLink
} from 'interfaces/ui/publicprofile/ui-public-manfredite';
import {
  StyledData,
  StyledDataItem,
  StyledHeader,
  StyledIntro,
  StyledLocation,
  StyledSocial
} from './PublicProfileHeader.styled';

type PublicProfileHeaderProps = {
  publicManfredite: UIPublicManfredite;
  showContactInfo?: boolean;
};

const PublicProfileHeader: React.FC<PublicProfileHeaderProps> = ({
  publicManfredite: {
    avatarUrl,
    avatarAlt,
    firstName,
    lastName,
    headline,
    whereDoYouLive,
    links,
    phoneNumber,
    email
  }
}) => {
  const { t } = useI18n('publicProfile');

  const renderLinks = (links: UIPublicManfrediteLink[]) => {
    const icons: { [key: string]: any } = {
      twitter: faTwitter,
      github: faGithub,
      stackOverflow: faStackOverflow,
      linkedIn: faLinkedin,
      other: faLink
    };

    return (
      <StyledSocial>
        {links.map((l, i) => (
          <TooltipMF content={l?.desc} direction="up" key={`link_${i}`}>
            <ButtonLink
              sizes="sm"
              variant="tertiary"
              isIcon
              href={l.url}
              target="_blank"
            >
              <FontAwesomeIcon icon={icons[l.type]} />
            </ButtonLink>
          </TooltipMF>
        ))}
      </StyledSocial>
    );
  };
  return (
    <StyledHeader>
      {avatarUrl && (
        <Avatar avatarImg={avatarUrl} avatarAlt={avatarAlt || ''} sizes="lg" />
      )}

      {(firstName || lastName) && (
        <Heading1 editorial>
          {firstName} {lastName}
        </Heading1>
      )}

      {!firstName && !lastName && (
        <Heading1 editorial>{t('unknownManfredite')}</Heading1>
      )}

      {headline && (
        <StyledIntro>
          <p>{headline}</p>
        </StyledIntro>
      )}

      {whereDoYouLive && (
        <StyledLocation>
          {t('header.in')} <strong>{whereDoYouLive}</strong>
        </StyledLocation>
      )}

      {(email || phoneNumber) && (
        <StyledData>
          {email && (
            <StyledDataItem>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${email}`}>{email}</a>
            </StyledDataItem>
          )}
          {phoneNumber && (
            <StyledDataItem>
              <FontAwesomeIcon icon={faPhone} />
              <span>{phoneNumber}</span>
            </StyledDataItem>
          )}
        </StyledData>
      )}

      {links && renderLinks(links)}
    </StyledHeader>
  );
};

export default PublicProfileHeader;
