import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing
} from '../../../theme';
import { StyledShowMore } from './about-me/ClassicCVAbout.styled';

export const StyledCard = styled.div`
  margin-bottom: ${spacing('lg')};
  padding: ${spacing('lg')};
  border-radius: ${radius('lg')};
  border: 1px solid ${color('midnight_200')};

  @media (min-width: ${screen('md')}) {
    display: grid;
    grid-template-columns: 14.25rem 1fr;
    padding: ${spacing('xl')};
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    align-items: flex-end;
    border-right: 1px solid ${color('midnight_200')};
    padding-right: ${spacing('lg')};
    margin-right: ${spacing('lg')};
    margin-bottom: 0;
  }

  img {
    margin-bottom: ${spacing('md')};
    display: block;
  }
`;

export const StyledLogoTitle = styled.div`
  font-size: ${fontSize('lg')};
  line-height: 1.16;
  font-weight: ${fontWeight('black')};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
  }
`;

export const StyledLogoEduTitle = styled.a`
  font-size: ${fontSize('sm')};
  line-height: 1.25;
  font-weight: ${fontWeight('medium')};
  text-transform: uppercase;
  text-align: right;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
  }
`;

export const StyledLink = styled.div`
  width: 100%;
  border-top: 1px solid ${color('midnight_200')};
  margin-top: ${spacing('xs')};
  padding-top: ${spacing('xs')};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('sm')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${screen('md')}) {
    text-align: right;
    font-size: ${fontSize('md')};
  }
`;

export const StyledContent = styled.div`
  ${StyledShowMore}::after {
    display: none;
  }
`;

export const StyledTitle = styled.h4`
  font-size: ${fontSize('lg')};
  line-height: 1.2;
  font-weight: ${fontWeight('medium')};
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
    line-height: 1.16;
  }
`;

export const StyledPreTitle = styled.div`
  font-size: ${fontSize('sm')};
  line-height: 1.2;
  text-transform: uppercase;
  color: #999;
  font-weight: ${fontWeight('medium')};
  margin: 0;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('sm')};
    line-height: 1.16;
  }
`;

export const StyledDate = styled.div`
  margin-top: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;
  font-weight: ${fontWeight('extraBold')};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spacing('xs')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
    line-height: 1.25;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing('xs')};
  }
`;

export const StyledDateRange = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  > * {
    display: flex;
    align-items: center;
  }

  > :first-child::after {
    content: '';
    width: ${spacing('md')};
    height: 1px;
    background-color: ${color('text_default')};
    margin-left: ${spacing('xs')};
    margin-right: ${spacing('xs')};
  }
`;

export const StyledEllapsedTime = styled.span`
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle')};
  white-space: nowrap;
`;

export const StyledTriggerIcon = styled.div`
  margin-left: auto;
  padding-left: ${spacing('lg')};
  color: ${color('midnight_300')};

  svg {
    width: ${spacing('lg')} !important;
    height: ${spacing('lg')};
  }
`;

export const StyledTrigger = styled.button`
  -webkit-appearance: none;
  width: 100%;
  display: flex;
  align-items: baseline;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  text-align: left;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${color('link_hover')};
  }

  &:active {
    color: ${color('link_active')};
  }

  &.is-active {
    ${StyledTriggerIcon} svg {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  > span {
    word-break: break-word;
  }
`;

export const StyledExtraContent = styled.div`
  overflow: hidden;
  margin: 0;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;

  &.is-active {
    max-height: var(--content-height);
  }
`;

export const StyledDesc = styled.div`
  padding-top: ${spacing('lg')};
  font-size: ${fontSize('sm')};
  line-height: 1.5;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('md')};
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-left: ${spacing('md')};
    margin-top: ${spacing('md')};
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }
`;

export const StyledTechsTitle = styled.span`
  display: block;
  font-size: ${fontSize('xs')};
  line-height: 1.14;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: ${fontWeight('black')};
  margin-top: ${spacing('xl')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('sm')};
  }
`;

export const StyledTechsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing('md')};
  margin-top: ${spacing('sm')};
`;

export const StyledTechsItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing('xs')};
  font-size: ${fontSize('xs2')};
  font-weight: ${fontWeight('medium')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('sm')};
  }
`;

export const StyledTechsLogo = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: ${spacing('xl')};

  img {
    width: ${spacing('lg')};
    height: ${spacing('lg')};
    display: block;
    filter: grayscale(100%);

    @media (min-width: ${screen('md')}) {
      width: ${spacing('xl')};
      height: ${spacing('xl')};
    }
  }
`;
