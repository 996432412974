import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledFlag } from './BadgeFlag.styled';

type BadgeFlagProps = {
  color: string;
  icon?: IconProp;
  text: string;
};

const BadgeFlag = ({ icon, text, color }: BadgeFlagProps) => {
  return (
    <StyledFlag className={`${color} ${icon ? '' : 'without_icon'}`}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {text}
    </StyledFlag>
  );
};

export default BadgeFlag;
