import styled, { css } from 'styled-components';
import { radius, spacing } from 'theme';

// styled-components.
// -----------------------------------------------------------------------------
export const ClassicCVLinkedInRoot = styled.div<{
  firstOfColumn: boolean;
  firstOfRow: boolean;
}>`
  ${({ firstOfColumn, firstOfRow }) => css`
    /* Positioning */
    position: relative;
    z-index: 20;

    ${firstOfColumn &&
    `
      margin-bottom: 50px;
      margin-top: -72px;
    `}

    ${firstOfRow &&
    `
      margin-top: -30px;
    `}
  `}
`;
export const ClassicCVLinkedInIcon = styled.div`
  /* Display & Box Model */
  align-items: center;
  border-top-right-radius: ${radius('md')};
  border-top-left-radius: ${radius('md')};
  background-color: #30abd5;
  display: flex;
  padding: ${spacing('xs')};
  height: ${spacing('xl')};
  width: fit-content;

  /* Text */
  font-size: 12px;
  font-weight: 900;
  line-height: 12px;

  /* Color */
  color: white;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    margin-left: 8px;
  }
`;

export const ClassicCVLinkedInContent = styled.div<{ isSection: boolean }>`
  ${({ isSection }) => css`
    /* Display & Box Model */
    border-top-right-radius: ${radius('md')};
    border-bottom-left-radius: ${radius('md')};
    border-bottom-right-radius: ${radius('md')};
    padding: ${spacing('xs')} ${spacing('sm')};

    /* Color */
    background-color: white;

    ${isSection && `padding: 40px 20px 20px;`}

    h1 {
      line-height: 1 !important;
      margin-bottom: 0 !important;
    }
  `}
`;
