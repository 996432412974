import { useI18n } from 'hooks/useI18n';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_LINKEDIN_COMBINATION } from './ClassicCVHeaderData.constants';
import {
  LinkedInCombination,
  UseClassicHeaderData
} from './ClassicCVHeaderData.types';

// Custom Hook.
// -----------------------------------------------------------------------------
export const useClassicHeaderData: UseClassicHeaderData = ({
  email,
  linkedIn,
  manfredite,
  phone
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const [combination, setCombination] = useState<LinkedInCombination>(
    DEFAULT_LINKEDIN_COMBINATION
  );
  const { t } = useI18n('publicProfile');

  // Variables.
  // ---------------------------------------------------------------------------

  const hasNameInfo = useMemo(
    () => !!manfredite.firstName || !!manfredite.lastName,
    [manfredite.firstName, manfredite.lastName]
  );

  // Effects.
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const { name, position, whereDoYouLive } = linkedIn;

    // Has 'whereDoYouLive' info.
    if (whereDoYouLive) {
      if (name && position && !email && !phone) {
        setCombination('location-name-position');
      } else {
        setCombination('location-name');
      }

      // Doesn't have 'whereDoYouLive' info.
    } else {
      if (name && position) {
        setCombination('name-position');
      } else if (name) {
        setCombination('only-name');
      } else if (!name && !position) {
        setCombination('none');
      }
    }
  }, [email, linkedIn, phone]);

  // Return.
  // ---------------------------------------------------------------------------
  return {
    combination,
    hasNameInfo,
    t
  };
};
