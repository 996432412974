import BadgeFlag from 'components/shared/BadgeFlag';
import MarkdownComponent from 'components/shared/MarkdownComponent';
import { formatDateMonthYear } from 'utils/date';
import {
  StyledDate,
  StyledDateRange,
  StyledDesc,
  StyledTechsItem,
  StyledTechsList,
  StyledTechsLogo,
  StyledTechsTitle
} from '../ClasicCVCard.styled';
import { useClassicCVProjectCard } from './ClassicCVProjectCard.hooks';
import {
  StyledCard,
  StyledContent,
  StyledFlagContainer,
  StyledLink,
  StyledLogo,
  StyledTitle
} from './ClassicCVProjectCard.styled';
import { ClassicCVProjectCardProps } from './ClassicCVProjectCard.types';
import { renderEllapsedTime } from './ClassicCVProjectCard.utils';

// Component.
// -----------------------------------------------------------------------------
export const ClassicCVProjectCard: React.FC<ClassicCVProjectCardProps> = ({
  profileItem
}) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { icon, locale, t, text } = useClassicCVProjectCard({ profileItem });

  // Helpers.
  // ---------------------------------------------------------------------------
  const renderDates = () =>
    profileItem.dateInitial ||
    (profileItem.dateSingle && (
      <StyledDate>
        <div>
          {profileItem.dateInitial && profileItem.dateFinal && (
            <>
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                {profileItem.dateFinal && (
                  <span>
                    {formatDateMonthYear(profileItem.dateFinal, locale)}
                  </span>
                )}
              </StyledDateRange>
              {renderEllapsedTime({
                from: profileItem.dateInitial.toString(),
                t,
                to: profileItem.dateFinal.toString()
              })}
            </>
          )}

          {profileItem.dateInitial && !profileItem.dateFinal && (
            <>
              <StyledDateRange>
                <span>
                  {formatDateMonthYear(profileItem.dateInitial, locale)}
                </span>
                <span>{t('timeline.today')}</span>
              </StyledDateRange>
              {renderEllapsedTime({
                from: profileItem.dateInitial.toString(),
                t,
                to: new Date().toString()
              })}
            </>
          )}
          {profileItem.dateSingle && !profileItem.dateInitial && (
            <span>{formatDateMonthYear(profileItem.dateSingle, locale)}</span>
          )}
        </div>
      </StyledDate>
    ));

  const renderDesc = () =>
    profileItem.desc && (
      <StyledDesc>
        <StyledTechsTitle>{t('description')}</StyledTechsTitle>
        <MarkdownComponent content={profileItem.desc} />
      </StyledDesc>
    );

  const renderImage = () =>
    profileItem.cardType === 'project' &&
    profileItem.logoUrl && (
      <img src={profileItem.logoUrl} alt={profileItem.logoAlt} />
    );

  const renderLink = () => (
    <StyledLink>
      <span>{t('url')}:</span> <a href={profileItem.link}>{profileItem.link}</a>
    </StyledLink>
  );

  const renderTechs = () =>
    profileItem.techs &&
    profileItem.techs.length > 0 && (
      <div>
        <StyledTechsTitle>{t('timeline.techs')}</StyledTechsTitle>
        <StyledTechsList>
          {profileItem.techs?.map((t, i) => (
            <StyledTechsItem key={i}>
              <StyledTechsLogo>
                <img src={t.iconUrl} alt={t.iconAlt} />
              </StyledTechsLogo>
              <span>{t.name}</span>
            </StyledTechsItem>
          ))}
        </StyledTechsList>
      </div>
    );

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <StyledCard>
      <StyledLogo>
        <StyledFlagContainer>
          <BadgeFlag {...icon} text={text} />
          {profileItem.link ? (
            <StyledTitle as="a" href={profileItem.link}>
              {profileItem.title}
            </StyledTitle>
          ) : (
            <StyledTitle>{profileItem.title}</StyledTitle>
          )}
        </StyledFlagContainer>
        {renderDates()}
        {renderImage()}
      </StyledLogo>

      <StyledContent>
        {renderDesc()}
        {renderLink()}
        {renderTechs()}
      </StyledContent>
    </StyledCard>
  );
};
